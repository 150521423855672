import { border, height, width } from '@mui/system'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import img from '../../Images/Background/gradient.jpg'
import {ApplyMarketPlace} from '../../api/marketPlace'
import styled from 'styled-components'
import Error from '../../shared/error';
import keyUri from '../../key'
import { useForm } from "react-hook-form";
import axios from 'axios'
import {useParams} from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { AiOutlineRight, AiOutlineCloudUpload} from 'react-icons/ai';
import ErrorMessage from '../../shared/errorMessage'
import ReactLoading from 'react-loading';
import Loading from '../../shared/loading' 


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


export default function Upload() {
    
const dispatch = useDispatch()
let history = useHistory();

const [file,setFile] = useState('')
console.log(file);
const [filename,setFilename]=useState('')
const {id} = useParams()
const [submit,setSubmit]=useState(false)

console.log(submit);

const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

    mode:"onTouched"
});



useEffect(()=>{

}, [dispatch])


console.log(file);

const onSubmit = async (value) => {

    const formData = new FormData()
    formData.append('file',file)
    formData.append('offer',value.priceTo)
    formData.append('id',id)
    console.log(formData);

    const data ={
        "language_ids": [],
        "mood_ids": [],
        "gener_ids": [],
        "keyword":"",
        "offset":0
     }


     if( file &&  value.priceTo){
        setSubmit(true)
        dispatch(ApplyMarketPlace(formData, history, data))
     }
     else{
         ErrorMessage('Mandatory field to be filled')    
     }

  };



const props ={
    onColor:"#F99E0B",
    onHandleColor:"#FDFEFE",
    handleDiameter:20,
    uncheckedIcon:false,
    checkedIcon:false,
    boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow:"0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
    height:25,
    width:60,
    className:"react-switch",
    id:"material-switch"
}



    const onChange = e =>{
            setFile(e.target.files[0])
            setFilename(e.target.files[0].name)
    }


    return (


        <div className=" min-h-screen bg-fixed bg-cover bg-center  pt-28 pb-20 px-20" style={{backgroundImage:`url('${img}')`,backgroundAttachment:'fixed',backgroundPosition:'center'}}>

{/* {
           loading ? <Loading/> :
             */}
        <div className=" grid grid-cols-2 gap-x-5 container mx-auto  ">

       
        <form onSubmit={handleSubmit(onSubmit)}>
                
            <div>
                
            <h1 className=" text-xl text-white pt-10 " style={{fontSize:"2rem",letterSpacing:"1px"}}>UPLOAD TRACK</h1>
          <div className=" text-xl text-white pt-12 " >
            <h1 style={{fontSize:"1rem"}}>Upload track</h1>
          {/* <div className="flex  items-center mt-3">

                
            <input {...register("song", { required: true })} type="file" id="myFile" onChange={onChange} />
            {errors.name?.type === "required" && <Error msg="required!" />}

            </div> */}

<div className="flex  items-center mt-4">

            {/* <figure>
                <audio className=" bg-transparent     focus:outline-none"style={{width:'95%'}}  controls  controlsList="nodownload noplaybackrate "
                    src={filename}>
                        Your browser does not support the
                        <code>audio</code> element.
                </audio>
            </figure> */}
            <div>  
            {/* <span>< AiOutlineCloudUpload /><input {...register("song", { required: true })} type="file" id="myFile" onChange={onChange}  /> </span>
            {errors.name?.type === "required" && <Error msg="required!" />} */}


    <label for="file-upload" class="custom-file-upload">
        < AiOutlineCloudUpload className=" text-white text-5xl"/>
    </label>
    <input id="file-upload" type="file" onChange={onChange} />
</div>
{file && <p className=" text-white px-5 ">{filename}</p>}

 </div>
         </div>

        <hr className="my-6" style={{width:'100%'}}/>

        </div>

        <div>
        <h1 className=" text-l text-white pt-4 ">Offer price</h1>
                
         <div className="flex  items-center text-sm pt-3" >

         <input {...register("priceTo", { required: true })}  type="number" min={0} id="priceTo"  placeholder="Price" className=" w-44 placeholder-gray-300  text-white rounded-md  px-4 border-2 border-white-50 py-2 bg-transparent focus:border-white   outline-none transition-colors duration-200 ease-in-out" />
         {errors.name?.type === "required" && <Error msg="required!" />}

        </div> 


        <div className="flex justify-left pt-24">

      <input className="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 focus:outline-none 
         hover:bg-yellow-500 rounded-lg  text-lg px-32 mt-8 cursor-pointer "  value={submit ? 'Loading...' :'UPLOAD' }  type="submit" disabled={submit} />
            </div> 

            </div>

    </form> 

        </div>
        {/* } */}
        </div>



    )
}











