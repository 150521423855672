import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import {authenticateSelector, ResetPassword} from '../../api/authSlice'
import { useForm } from "react-hook-form";
import Error from '../../shared/error';
import styled from 'styled-components'
import ErrorMessage from '../../shared/errorMessage'


export default function Profile() {
  const {  phone, user } = useSelector(authenticateSelector)
  const dispatch = useDispatch()

  const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

    mode:"onTouched"
});

const onSubmit = (value) => {
  const data = {
    password:value.password
  }

  if(value.password !== value.conformpassword){
    ErrorMessage("Password do not match")
  }
  else{
    dispatch(ResetPassword(data))
}
};

  

    return (
      <div style={{backgroundImage: `url(${Backgroundimage})`}} className="h-screen bg-fixed bg-cover bg-center">
            
      <section class="text-gray-600 body-font">
<div class="container px-5 py-32 pt-40 mx-auto flex flex-wrap items-center">
<div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
<p class="flex justify-right font-extrabold tracking-wider text-4xl text-white mb-2">RESET PASSWORD</p>

<div class="relative mb-4 py-10">
 
<form onSubmit={handleSubmit(onSubmit)}>

                <div class="relative mb-4">
               <input  {...register("password", { required: true })} type="password" id="password" placeholder="New Password" class=" w-full bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}

           <input  {...register("conformpassword", { required: true })}  type="password" id="conformpassword" placeholder="Confirm Password"  class=" w-full bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}


          </div>
         
          <input className="text-white bg-yellow-400 font-bold border-0 w-full py-4 px-8 border-black mb-10 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg"  value="GO TO SIGN IN"   type="submit" />

          </form> 



</div>

</div>
</div>

</section>
  </div>
    )
}


const Wrap = styled.div`


`