import {createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../key'
import { useHistory } from 'react-router-dom';
import { toast} from 'react-toastify'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null

const loginConfig  = {
  headers: {
    Authorization: token,
  },
}

export const initialState = {

    loading:false,
    hasErrors:false,
    isAuthenticate:  token ? true : false,
    user:null,
    token:token,
    token:token,
    phone : null,
}

export const authenticateSlice = createSlice({


    name:"auth",
    initialState,
    reducers:{

      getlogin:state =>{

        state.loading = true;
      },

      getAuthenticate: (state, {payload}) =>{

        state.loading = false;
         state.isAuthenticate = true 
         state.user = payload.admin
         state.token = payload.token

      },
      isAuthenticateError: state =>{

        state.hasErrors = true;
        state.loading = false;
        state.isAuthenticate = false

      },
      getUserProfile: (state, {payload})=>{
        state.loading = false;
        state.user = payload.data;
        state.isAuthenticate = true;
      },

      getOtpSentSuccess: (state ,{payload})=>{
        console.log(payload);
        state.loading = false;
        state.phone  = payload;

      },
    }
})


export const {getlogin,getAuthenticate, getUserProfile, isAuthenticateError , getOtpSentSuccess}  = authenticateSlice.actions

export const authenticateSelector = state => state.auth
export default authenticateSlice.reducer


const config = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
};


export const logOut = () => async dispatch =>{
    // const key = 'logOut';
    try {      
        localStorage.removeItem('access_token');
        window.location.reload();
    } catch (error) {
        dispatch(isAuthenticateError())
    }
}


export const fetchlogin = (logindata) => async dispatch =>{
    const key = 'login';
    dispatch(getlogin())
    try {
        
        const {data} = await axios.post(keyUri.BACKEND_URI + '/login', logindata, config)        
        dispatch(getUserProfile(data))
        localStorage.setItem('access_token', data.access_token )
        data && SuccessMessage(data.message)

    } catch (error) {

      error &&  ErrorMessage(error?.response?.data?.message)
        dispatch(isAuthenticateError())
    }
}



export const fetchProfile = () => async dispatch => {

  console.log('profile');


  dispatch(getlogin())
  try {
    const {data} = await axios.get(keyUri.BACKEND_URI + '/get-profile',  loginConfig)

    if(data.status){
      dispatch(getUserProfile(data))
    }
    else{
      dispatch(logOut())
    }

  } catch (error) {
          error && ErrorMessage('Authentication Failure')
          dispatch(logOut())
  }
}



export const fetchMobileRegister = (registerdata, history) => async dispatch =>{
  const key = 'register';
  dispatch(getlogin())
  
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/register-phone', registerdata, config)

      data && history.push(`/verify-otp/register`)
      dispatch(getOtpSentSuccess(registerdata))
      data && SuccessMessage(data.message)

  } catch (error) {
       error && ErrorMessage(error?.response?.data?.message)
       dispatch(isAuthenticateError())
  }
}




export const verifyOTP = (otpdata, history) => async dispatch =>{
  const key = 'otp';
  dispatch(getlogin())
  console.log(otpdata);
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/verify-otp', otpdata, config)
      dispatch(getUserProfile(data))
      localStorage.setItem('access_token', data.access_token )
      // data && SuccessMessage(data.message)

  } catch (error) {
      error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}




export const resendOtp = (phone) => async dispatch =>{
  const key = 'register';
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/resend-otp', phone, config)
      data && SuccessMessage(data.message)

  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(isAuthenticateError())
  }
}



export const forgotPassword = (forgotdata, history) => async dispatch =>{
  const key = 'forgotpassword';
  dispatch(getlogin())
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/forgot-password', forgotdata,config)

     data && history.push(`/verify-otp/forgot-password`)

     dispatch(getOtpSentSuccess(forgotdata))

     data && SuccessMessage(data.message)

  } catch (error) {
     error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}




export const ResetPassword = (resetdata) => async dispatch =>{

  dispatch(getlogin())
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/reset-password',resetdata,loginConfig)

      data && SuccessMessage(data.message)
      localStorage.removeItem('access_token');
        window.location.reload();

  } catch (error) {
      error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}




export const userRegister = (regdata) => async dispatch =>{
console.log(regdata);
  dispatch(getlogin())
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/register',regdata,config)

      dispatch(getUserProfile(data))
      localStorage.setItem('access_token', data.access_token )
      data && SuccessMessage(data.message)

  } catch (error) {

    error && ErrorMessage(error?.response?.data?.message)

      dispatch(isAuthenticateError())
  }
}


export const socialLogin = (socialdata) => async dispatch =>{
  const key = 'sociallogin';
  dispatch(getlogin())
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/social-login',socialdata,config)

      console.log(data);

     // data &&  message.success({ content: data.message, key, duration: 2 });

  } catch (error) {
     error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}


export const fetchChoosegeneres = (musicdata,history) => async dispatch =>{
  const key = 'choosegeneres';
  dispatch(getlogin())
  try {
    console.log(musicdata);
      const {data} = await axios.post(keyUri.BACKEND_URI + '/choose-generes', musicdata,config)
      
      // data && SuccessMessage(data.message)
      data && history.push(`/user/home`)
      
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}


export const updateProfile = (profiledata) => async dispatch =>{
      console.log(profiledata);

  dispatch(getlogin())
  try {
 
      const {data} = await axios.put(keyUri.BACKEND_URI + '/update-profile',profiledata,loginConfig)
      data && dispatch(fetchProfile())
      data && SuccessMessage(data.message)
    
  } catch (error) {
    console.log(error);
    // error && ErrorMessage(error?.response?.data?.message)
      // dispatch(isAuthenticateError())
  }
}

export const uploadImage = (values) => async dispatch =>{

  dispatch(getlogin())
  try {
 
      const {data} = await axios.post(keyUri.BACKEND_URI + `/upload-image`,values,loginConfig)
      // data && SuccessMessage(data.message)
    
  } catch (error) {
    console.log(error);
    error && ErrorMessage(error?.response?.data?.message)
      // dispatch(isAuthenticateError())
  }
}



export const updateUserType = (history) => async dispatch =>{

  dispatch(getlogin())
  try {
 
      const {data} = await axios.get(keyUri.BACKEND_URI + '/update-user-type', loginConfig)
      data && dispatch(fetchProfile())
      data && history.push(`/user/home`)
      // if(data){
      //   window.location.href = '/user/home'
      // }
      data && SuccessMessage('You are now logined as Artist')

  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
      dispatch(isAuthenticateError())
  }
}


