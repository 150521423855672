import React, {useEffect,useState} from 'react'
import homeimg from '../../Images/Background/bg20.jpg'
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from 'react-router-dom'
// import homeimg from '../../Images/Background/gradient.jpg'
import {getMyPostRequest,marketSelector,fetchMarketPlaceRequestList} from '../../api/marketPlace'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {authenticateSelector} from '../../api/authSlice'
import ReactLoading from 'react-loading';
import Loading from '../../shared/loading' 


export default function Home() {
    const dispatch = useDispatch()
    const {market_list, loading } = useSelector(marketSelector)
    const { user } = useSelector(authenticateSelector)

    console.log(market_list);
    // console.log(user);


 const data ={
    "language_ids": [],
    "mood_ids": [],
    "gener_ids": [],
    "keyword":"",
    "offset":0
 }

    
    useEffect(()=>{
        if(user?.aboutus === 'ARTIST'){
            dispatch(fetchMarketPlaceRequestList(data))
        }
        if(user?.aboutus === 'LISTENER'){

            dispatch(getMyPostRequest(0))
        }
    }, [user])


    return (
     <div className="min-h-screen bg-fixed bg-cover bg-center  pb-4  pt-20" style={{backgroundImage:`url('${homeimg}')`}}>
          
          {
           loading ? <Loading/> :
             
           <>
  
<div className=" flex pl-48 ">

        <Link to="/user/marketplace/songrequest"><button className="  text-white bg-black bg-opacity-40 px-6 py-2 m-4 w-48 rounded"> <div className="flex justify-between items-center"><AiOutlinePlus className="text-xl" /> Song Request</div></button> </Link>

        <Link to="#"><button className=" text-white bg-black bg-opacity-40  rounded  px-6 py-2 m-4 "> Genre </button></Link>
        <Link to="#"><button className=" text-white bg-black bg-opacity-40 rounded px-6 py-2 m-4"> Language </button></Link>
        <Link to="#"><button className=" text-white bg-black bg-opacity-40 rounded px-6 py-2 m-4"> Mood </button></Link>

</div>

        <div className="flex justify-center items-center flex-col pt-6 "> 
     {          
          market_list.map((item, i)=>{ 

       return <div key={i} className=" bg-gray-900 bg-opacity-70 backdrop-filter backdrop-blur w-3/4 shadow-xl rounded-xl p-6 my-3 grid grid-cols-4" >


            <Link to={item?.post_by === user?.id ? `/user/marketplace/songrequest/${item?.id}` : '#'}  className="col-span-1">
            <h1 className=" text-white  text-base font-bold mt-1 ml-2 ">{item?.title}</h1>
             <h2 className="text-white mt-3 text-sm ml-2 ">{item?.description}</h2>
            </Link>

            <Link to={item?.post_by === user?.id ? `/user/marketplace/songrequest/${item?.id}` : '#'}  className="col-span-3 text-white">

                    <div className="grid grid-cols-9">
                        <h2 className="col-span-1 text-sm my-auto" >Genres :</h2>
                        <div className="col-span-8 grid grid-cols-7">
                        {
                        item?.musicgenres.map((btn, i)=>{
                            return <button key={i} style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2 px-0 py-1 rounded-lg ">{btn?.name}</button>
                        })
                        }
                    </div>
                    </div>

                    <div className="grid grid-cols-9 my-3">
                        <h2 className="col-span-1 text-sm  my-auto" >Language :</h2>
                        <div className="col-span-8 grid grid-cols-7">
                        {
                        item?.musiclanguages.map((btn, i)=>{
                            return <button key={i} style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2 px-0 py-1 rounded-lg ">{btn?.name}</button>
                        })
                        }
                    </div>
                    </div>


                    <div className="grid grid-cols-9 ">
                        <h2 className="col-span-1 text-sm  my-auto" >Mood :</h2>
                        <div className="col-span-8 grid grid-cols-7">
                             <button key={i} style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2  py-1 rounded-lg">{item?.mood?.name}</button>
                    </div>
                    </div>
               
            </Link>



            <div className="flex justify-between items-end  col-span-4">


            <Link to={item?.post_by === user?.id ? `/user/marketplace/songrequest/${item?.id}` : '#'} className="w-full">
                <h1 className="text-xs text-white ml-2 mt-3" style={{fontSize:"0.85rem",}}  > Expected by :  08 oct 21 </h1>
                <h1 className="text-xs text-white ml-2 mt-1" style={{fontSize:"0.85rem",}}  > Budget :  $500 </h1>
            </Link>
                    { (user?.aboutus === 'ARTIST' && item?.post_by !== user?.id  ) && <Link to={`/user/marketplace/uploadtrack/${item.id}`}> 
                     <button class={`text-white border-black shadow-sm  font-bold border-0 focus:outline-none ${item?.request_id === 0 ? 'bg-yellow-400 hover:bg-yellow-500' : 'bg-green-500 hover:bg-green-600'} 
                    rounded-lg  text-sm px-1 w-28 h-8  tracking-wider ` }>{ item?.request_id === 0 ? 'APPLY' : 'APPLIED' }</button></Link>
                    }
            </div>     
                  
            </div>

            }

)}
         </div>

</>
}
     </div>
       
    )
}


const StyleWrap = styled.div`

.footer{
    

}
`