import React,{useState} from 'react'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './form.css'
import {forgotPassword} from '../../api/authSlice'
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'



export default function ForgotPassword () {

  const dispatch = useDispatch()
  let history = useHistory();
  const [phoneNo, setPhoneNo] = useState(null)
  const [code, setcode] = useState(null)


const onSubmit = () => {
  const data = {
    mobile:phoneNo,
    country_code:`+${code}`,
}
console.log(data);
  dispatch(forgotPassword(data, history))
};


  const onChangePhone = (value, data, event, formattedValue) =>{
      setPhoneNo(value.slice(data?.dialCode?.length))
      setcode(data.dialCode)
}


    return (
        <div style={{backgroundImage: `url(${Backgroundimage})`}} className="h-screen bg-fixed bg-cover bg-center">
            
            <section class="text-gray-600 body-font">
  <div class="container px-5 py-32 pt-40 mx-auto flex flex-wrap items-center">
    <div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
    <p class="flex justify-right font-extrabold tracking-wider text-4xl text-white mb-2">FORGOT PASSWORD</p>
    <p class="flex justify-right font-thin tracking-wider text-sm text-white">Reset your password</p>
      <div class="relative mb-4 py-10">
       
        
      <PhoneInput id="phone"
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true
        }}
         onChange={(value, data, event, formattedValue) => onChangePhone(value, data, event, formattedValue)}
      />


      </div>
    <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-4 px-8 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg" onClick={onSubmit}>SEND CODE</button>

    </div>
  </div>
  
</section>
        </div>
    )
}

