import React from 'react'
import Modal from 'react-modal';
import { AiFillStar} from 'react-icons/ai';
import Error from '../../shared/error';
import { useForm } from "react-hook-form";
import { HiX} from 'react-icons/hi';
import {sendOffer} from '../../api/music'
import {useDispatch, useSelector} from 'react-redux'


export default function Popuplicense({song}) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [thankyouOpen, setThankyOpen] = React.useState(false);
    const dispatch = useDispatch()

    
    const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

      mode:"onTouched"
  });


    function openModal() {
        setIsOpen(true);
      }

      function closeModal() {
        setIsOpen(false);
      }
    

      function openThankModal() {
        setThankyOpen(true);
      }

      function closeThankModal() {
        setThankyOpen(false);
      }
    

      const onSubmit = (value) => {
   
        const data = {
          duration: value.duration,
          price:value.price,
          id: song
        }
         dispatch(sendOffer(data))
        setIsOpen(false);
        setThankyOpen(true);
      };


    return (
        <div>
          {/* <button onClick={openModal}>NEXT</button> */}
          <button  onClick={openModal} class="text-white border-black shadow-sm bg-yellow-500 font-bold border-0 py-4 px-20 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg">LICENSE THIS SONG</button>  

          {/* <button  onClick={openThankModal} class="text-white border-black shadow-sm bg-yellow-500 font-bold border-0 py-4 px-20 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg">LICENSE </button>   */}


      <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{

            width:"38%",
            height:"54%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.7,
            colopr:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >

<div className="text-white mt-4 2xl:mt-5">


        <button className="float-right block transform -translate-y-7 text-3xl" onClick={closeModal}><HiX/></button>

         <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" flex flex-col justify-center items-center mx-16 2xl:mx-24" >
        <h1 className="text-white mt-0 2xl:mt-1 text-3xl">License this song</h1>

         <br/>
         <h2 className="text-white  text-sm tracking-wide">License price is $100 to $200 for 6 months </h2>
        

        <div className=" text-sm mt-2  pt-4 rounded-sm w-full "> 

            <select  {...register("duration", { required: true })}  placeholder="Duration" id="duration" class=" py-3 w-full bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 px-4 leading-8 transition-colors duration-200 ease-in-out">
              <option style={{display:'none'}}>Duration</option>
              <option value="1_MONTH">1 month</option>
              <option value="2_MONTH">2 month</option>
              <option value="3_MONTH">3 month</option>
              <option value="4_MONTH">4 month</option>
              <option value="5_MONTH">5 month</option>
              <option value="6_MONTH">6 month</option>

          </select>
          {errors.name?.type === "required" && <Error msg="required!" />}
 
          <input {...register("price", { required: true })} type="number" id="price"  placeholder="Price" class=" py-2 w-full bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-sm text-gray-500 px-3 leading-8 transition-colors duration-200 ease-in-out" ></input>
          {errors.name?.type === "required" && <Error msg="required!" />}

       </div>    



        <a href="https://musicapp.cytess.com/song/pol" target="_blank" className="text-sm text-white mt-3 text-decoration: underline  self-start cursor-pointer">View sync offer terms</a>


<button class="bg-yellow-400 font-bold py-3 w-full mt-10 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg " > SEND OFFER   </button>


    </div>

</form> 

    </div>
      </Modal>


      <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{

            width:"35%",
            height:"38%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.7,
            color:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={thankyouOpen}
        onRequestClose={closeThankModal}
        contentLabel="Example Modal"
      >
        <div className="text-white mt-0 ">

        <div  className=" flex justify-end items-end" >
        <button className=" text-3xl" onClick={closeThankModal}> <HiX/> </button>
        </div>

        <div className=" flex flex-col justify-center items-center " >

        <div className="  bg-white rounded-full p-4">
        {/* < MdStars className="text-7xl text-white"/> */}

         <AiFillStar className="text-5xl text-yellow-500 "/>

        </div>

         <br/>
         <h1 className=" font-semibold text-4xl text-yellow-500 tracking-wide  ">THANK YOU</h1>
        

        <h3  className="text-white  text-sm pt-6 tracking-wide">Your Song Request has been successfully posted.</h3>
        <h3  className="text-white  text-sm pt-3 tracking-wide">Charlie will contact you soon.</h3>
       </div>
       </div>

      </Modal>
        </div>
    )
}