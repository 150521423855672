import React, {useState, useEffect} from 'react'
import { Layout, Affix } from 'antd';
import styled from 'styled-components'
import {  Route, Switch, useRouteMatch ,useParams,Link, useLocation } from 'react-router-dom';
// import Loader from '../components/shared/loader'
import {fetchProfile, authenticateSelector} from '../api/authSlice'
import Navbar from './Navbar/Nabar'
import  Home  from './Home';
import ViewHome from './Home/viewmore'
import Trending from './Trending'
import ViewTrending from './Trending/viewmore'
import RegisterPage from './Form/RegisterPage';
import subscription from './Form/Subscription';
import Marketplace from './Marketplace';
import SongMarketplace from './Marketplace/Songrequest'
import EditSongMarketplace from './Marketplace/editSongRequest'
// import  HomePage1  from './Home/page1';
import Plans from './Form/Subscription'
import  MusicPreference  from './Home/musicPreference';
import Songdetail from './Songs/Songdetail';
import Myprofile from './Profile/Myprofile';
import ResetPassword from './Form/ResetPassword';
import  SongRequest from './Marketplace/oneRequest'
import Songupload from './Songs/Songupload'
import UploadTrack from './Marketplace/uploadTrack'
import {useDispatch, useSelector} from 'react-redux'
import UploadP from './Songs/Songupload'
import RecordUpload from './Songs/Recordupload'
import MusicSearch from './Search/musicsearch'
import Demo from './Songs/demo'



export default function Admin() {

  let { path, url } = useRouteMatch();
  const {pathname} = useLocation()
  const {id} =useParams()

  console.log(pathname);
  console.log(path);
  console.log(url);
  console.log(id);


const dispatch = useDispatch()
const {loading, token,  user} = useSelector(authenticateSelector)
useEffect(()=>{
  dispatch(fetchProfile(token))
}, [])
    return (
      <> {
        // loading ? <p>loading..</p> :
        <AdminWrap >  
        {(pathname !=='/user/reset-password' ) && <Navbar />}
        <Switch>
        <Route  exact path={`${path}/home`}  component={Home} />
        <Route  exact path={`${path}/home/:id`}  component={ViewHome} />
        <Route  exact path={`${path}/trending`}  component={Trending} />
        <Route path={`${path}/trending/:id`} component={ViewTrending}/>
        <Route path={`${path}/subscription`} component={subscription} />
        <Route exact path={`${path}/music-preference`} component={MusicPreference} />
        <Route exact path={`${path}/marketplace`} component={Marketplace} />
        <Route exact path={`${path}/marketplace/songrequest`} component={SongMarketplace} />
        <Route exact path={`${path}/marketplace/editsongrequest/:id`} component={EditSongMarketplace} />
        <Route  exact path={`${path}/plans`}  component={Plans} />
        <Route exact path={`${path}/song/:id`} component={Songdetail} />
        <Route exact path={`${path}/myprofile`} component={Myprofile} />
        <Route path={`${path}/reset-password`} component={ResetPassword}/>
        <Route path={`${path}/marketplace/songrequest/:id`} component={SongRequest}/>
        <Route path={`${path}/upload-music`} component={Songupload}/>
        <Route path={`${path}/marketplace/uploadtrack/:id`} component={UploadTrack}/>
        <Route path={`${path}/upload-music`} component={UploadP}/>
        <Route path={`${path}/record-upload`} component={RecordUpload}/>
        <Route path={`${path}/demo`} component={Demo}/>
        <Route path={`${path}/search`} component={MusicSearch}/>

        
       </Switch>
      </AdminWrap>
      }
       </>
    )
}
const AdminWrap = styled.div`
`