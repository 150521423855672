import React from 'react'
import Loadingimg from '../Images/loading/LoopingGIF.gif' 

export default function Loading() {
  return (
    <div className=" col-span-5 flex justify-center items-center " style={{height:'75vh'}}> 
            <img src={Loadingimg} alt="Loading" width='125px'/>
    </div> 
  )
}
