import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Image2 from '../../Images/clasical.jpeg'
import {authenticateSelector,fetchChoosegeneres, userRegister} from '../../api/authSlice'
import {notificationSelector, updatenotification} from '../../api/notification'

import {commonSelector, fetchAllgenres} from '../../api/common'
import styled from 'styled-components'
import ErrorMessage from '../../shared/errorMessage'
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { BsFillCheckCircleFill } from 'react-icons/bs'


export default function Home() {
    const dispatch = useDispatch()
    let history = useHistory();

    const {  user } = useSelector(authenticateSelector)
    const { genres } = useSelector(commonSelector)
    const [generes, setGeneres] = useState([])
    const [modalIsOpen, setIsOpen] = React.useState(false);


    console.log(genres);

    useEffect(()=>{
        // if(user?.user_geners.length > 0){
        //     window.location.href = '/user/home'
        // }
      }, [user])
        
      
    useEffect(()=>{
        dispatch(fetchAllgenres()) 
    }, [dispatch])
  

    function openModal() {
        if(generes.length < 5){
            return  ErrorMessage("Choose minimum 5 Genres")
            }
        setIsOpen(true);
      }

      function closeModal() {
        setIsOpen(false);
      }

      
    
    const onClick = (item) =>{
        const exist = generes.findIndex((id) => id === item.id);
        if(exist !== -1 ){
            const array = generes.filter((id) => {
                return item.id !== id
            }) 
           setGeneres(array);
        }
        else{
            setGeneres([...generes, item.id])
        }
    }


    const onAllow = (text) => {
        const data ={
            userId:user.id,
            genreId:generes
        }
        const notifydata = {
            push:"Y",
            auto_play:"N",
            likes_on_tune:"N",
            new_followers:"Y",
            new_features:"N",
            suggested_content:"N",
            new_promotions:"Y"
        }

        if(text === 'skip'){
            dispatch(fetchChoosegeneres(data,history))
        }
        else{
          dispatch(fetchChoosegeneres(data,history))
          dispatch(updatenotification(notifydata,history))

        } 
    }   
    

    return (<>

         <div className=" pt-20 bg-fixed bg-cover grid grid-cols-3  min-h-screen " style={{background:`linear-gradient(203deg, rgba(255,58,57,1) 0%, rgba(242,42,100,1) 18%,  rgba(228,25,142,1) 33%, rgba(229,26,139,1) 33%, rgba(199,25,184,1) 60%, 
        rgba(130,25,228,1) 89%, rgba(116,16,210,1) 100%)`, backgroundAttachment:'fixed'}} >

        <div className="col-span-2 flex justify-between  items-center pl-20 pr-16  ">
            <div>
            <h1 className="text-3xl text-white pt-16 ">YOUR MUSIC PREFERENCE </h1>
            <h1 className=" text-white pt-2 ">Select at least five categories</h1>
            </div>

            <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-1 px-8 mt-12 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg " onClick={openModal}>
            NEXT</button>

        </div>


 <div className="col-span-2 grid grid-cols-4 2xl:grid-cols-5 overflow-y-auto pl-20 my-5 mb-12 overflow-x-hidden" style={{maxHeight:'70vh'}}>

        {
                 genres.map((item, i)=>{   

                    const exist = generes.findIndex((id) => id === item.id);
                    const image = item?.image.replace(/ /g,"%20")

                return <div key={i} className=" flex justify-start  pt-8 align-center" onClick={(e)=>onClick(item)}>

            <GeneresWrap> 
                <div className="selected h-44  bg-cover bg-center bg-origin-border rounded-2xl w-44 " 
                style={{backgroundImage:`url(${image})`, border: (exist !== -1)?'2px solid #fff' :'none', color:(exist !== -1)? '#FBBF24':'#fff'}}>   
                
                <div  className="flex justify-center items-center bg-black bg-opacity-20 backdrop-filter  w-full h-full rounded-2xl" >

                <h1 className=" px-4 text-center" style={{fontSize:'1.75rem'}}>{item.name}</h1>

                </div>

                </div>
            </GeneresWrap>
                </div>
            })
        }   

</div> 

        </div>

        <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(5px)',
          
          },
          content:{
            width:"55%",
            height:"45%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.8,
            colopr:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
          <div className="text-white mt-8">

            <button className="float-right block transform -translate-y-10" onClick={closeModal}>X</button>
            <div className=" flex flex-col justify-center items-center" >
            <h1 className="pt-6 text-4xl tracking-wide text-center ">CHARLIE Would like to </h1> 
            
            <h1 className="pt-2 text-4xl tracking-wide text-center"> send you notifications </h1> 

            <h2 className=" text-center text-sm tracking-wider font-normal pt-5">Notifications may include Alerts, New Tracks, and Trending Artists. </h2>
                        
            <h3  className=" text-center text-sm tracking-wide">Customize your preferences in Settings.</h3>

            <button class="bg-yellow-400 font-bold py-3 px-14 mt-12 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg " onClick={()=>onAllow('allow')}>
             ALLOW  </button>

        <h3 className="text-sm text-blue-600 text-center mt-3 cursor-pointer" onClick={()=>onAllow('skip')}>SKIP FOR NOW</h3>
        </div>

    </div>
      </Modal>
        </>
    )
}

const GeneresWrap = styled.div`

.selected{
position: relative;

/* &::after{
width:100%;
height: 100%;
position: absolute;
left:50%;
top:50%;
transform: translate(-50%, -50%);
background-color: #1c1c1c5c;
z-index: 1;
content: '';
border-radius: 1rem;
} */

}

.badge {
    position: absolute;
    z-index: auto;
    color: #fff;
}

.badge  {
    transform: translate(80px, -80px)
}

`