import { border, height, width } from '@mui/system'
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import img from '../../Images/Background/gradient.jpg'
import { AiOutlineRight } from 'react-icons/ai';
import Switch from "react-switch";
import {AiOutlineCloudUpload} from 'react-icons/ai';
import {SiAddthis}from 'react-icons/si';
import styled from 'styled-components'
import { commonSelector, fetchAllgenres, fetchLanguages, fetchPitch, fetchMoods, fetchBpms, fetchInstruments, fetchVocaltypes ,fetchTimesignatures} from '../../api/common'
import {  createMarketPlace } from '../../api/marketPlace'

import Error from '../../shared/error';
import { useForm } from "react-hook-form";
import Modal from 'react-modal';
import { AiFillStar} from 'react-icons/ai';
import { HiX} from 'react-icons/hi';
import ErrorMessage from '../../shared/errorMessage'


export default function Upload() {
    
const dispatch = useDispatch()
const [filter, setFilter]= useState('genres')

const { genres,language , mood } = useSelector(commonSelector)

const [genresList, setGenresList]= useState([])
const [languageList, setLanguageList]= useState([])
const [moodList, setMoodList]= useState(null)



const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

    mode:"onTouched"
});

useEffect(()=>{
    dispatch(fetchAllgenres())
    dispatch(fetchLanguages())
    dispatch(fetchMoods())
}, [dispatch])




const onSubmit = (value) => {

    const genre = genresList?.map(item => item.id)
    const language = languageList?.map(item => item.id)

    const data = { 
        title:value.title,
        description:value.description,
        priceTo:value.priceTo,
        priceFrom:0,
        expected_till_date:value.expected_till_date,
        geners:genre,
        languages:language,
        moodId:moodList?.id,
        // id:0,
    }

    if(genre.length > 0 && language.length > 0 && moodList ){
    dispatch(createMarketPlace(data))

    setThankyOpen(true);
    }
    else{
        ErrorMessage('Mandatory field to be filled')    
    }
  };



  const [thankyouOpen, setThankyOpen] = React.useState(false);

  function openThankModal() {
      setThankyOpen(true);
    }

    function closeThankModal() {
      setThankyOpen(false);
      window.location.href = '/user/marketplace'
    }



const props ={
    onColor:"#F99E0B",
    onHandleColor:"#FDFEFE",
    handleDiameter:20,
    uncheckedIcon:false,
    checkedIcon:false,
    boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow:"0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
    height:25,
    width:60,
    className:"react-switch",
    id:"material-switch"
}


    const onChangeSwitch = (id, filter)=>{

        switch (filter) {
            case 'genres':
                    const  istheregenres =  genresList.findIndex(item => item?.id === id?.id)

                    if (istheregenres !== -1 ){
                        let arr = genresList.filter(item => item?.id !== id?.id ) 
                        setGenresList(arr)

                    } else {
                        setGenresList([...genresList,id])
                    } 
                break;

            case 'language':
                const istherelanguage =  languageList.findIndex(item => item?.id === id?.id)

                    if (istherelanguage !== -1 ){
                        let arr = languageList.filter(item => item?.id !== id?.id ) 
                        setLanguageList(arr)

                    } else {
                        setLanguageList([...languageList,id])
                    } 
                break;

         
            case 'mood':
                setMoodList(id)
                break;
            }

    }



    return (
        <FormWrap>
            {/* <div className="min-h-screen bg-fixed bg-cover bg-center  pt-20" style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} > */}

            <div className=" min-h-screen bg-fixed bg-cover bg-center  pt-28 pb-20 px-20" style={{backgroundImage:`url('${img}')`,backgroundAttachment:'fixed',backgroundPosition:'center'}}>


        <div className=" grid grid-cols-2 gap-x-5 ">
            
        <form onSubmit={handleSubmit(onSubmit)}>
                
        <div className="pr-10 overflow-x-hidden  overflow-y-auto" style={{borderRight: " 1px solid white ", height:'80vh'}}>

                
         <h1 className=" text-xl text-white mb-5">Post a new requirement </h1>

         <div className="flex flex-col justify-start py-2">


            <input   {...register("title", { required: true }) } id="title" type="text" placeholder="Title*" className=" placeholder-white text-xl text-white focus:outline-none mb-3 " style={{backgroundColor:"transparent", border:"none"}}/> 
            {errors.name?.type === "required" && <Error msg="required!" />}

            <input  {...register("description", { required: true })} id="description"  type="text" placeholder="Description" className=" placeholder-gray-200 text-base text-white focus:outline-none " style={{backgroundColor:"transparent", border:"none"}}/>
            {errors.name?.type === "required" && <Error msg="required!" />}

        </div>



<hr className=" my-6" style={{width:'100%'}}/>

    

        <div>
      
    <div className="flex justify-start " >
    <div className="mr-28">
        <h1 className="text-white text-lg">I'm ready to pay * </h1>
    <br/>

    <input {...register("priceTo", { required: true })}  type="number" min={0} id="priceTo"  placeholder="" className=" w-44 placeholder-gray-300  text-white rounded-md  px-4 border-2 border-white-50 py-2 bg-transparent focus:border-white   outline-none transition-colors duration-200 ease-in-out" />
    {errors.name?.type === "required" && <Error msg="required!" />}
    </div>


    <div >
        <h1 className="text-white text-lg">I need a song by * </h1>
    <br/>
    <input {...register("expected_till_date", { required: true })} type="date" id="expected_till_date"  placeholder="Date" className=" w-44 text-sm placeholder-gray-300  text-white rounded-md  pl-3 pr-1 border-2 border-white-50 py-2 bg-transparent focus:border-white   outline-none transition-colors duration-200 ease-in-out" type="date" id="expected_till_date"  placeholder="date"/>
    </div>

    </div>


         <hr className="my-6" style={{width:'100%'}}/>

        <h1 className="text-white text-xl  pb-6 " >Song Details </h1>

        <div className="grid grid-cols-9 mb-4">
            <div className="col-span-8 ">
                 <h2 className="text-white text-base" onClick={()=>setFilter('genres')}>Genre*</h2>

                        {genresList?.length > 0 && <div className=" mt-2"  onClick={()=>setFilter('genres')}>
                    <div className=" grid grid-cols-4 gap-y-2 gap-x-5 text-xs text-white">
                    {
                        genresList.map(item =>  <button class="border-2 border-white-100 py-1 px-3 rounded-lg">{item?.name}</button> )
                    }
                    </div>

                    </div>}
            </div>
            <div className="flex justify-end"><  AiOutlineRight className="text-base my-auto text-white cursor-pointer" onClick={()=>setFilter('genres')}/></div>
        </div>


        <div className="grid grid-cols-9 mb-4">
            <div className="col-span-8 ">
                 <h2 className="text-white text-base" onClick={()=>setFilter('language')}>Language*</h2>

                        {languageList?.length > 0 && <div className=" mt-2"  onClick={()=>setFilter('language')}>
                    <div className=" grid grid-cols-4 gap-y-2 gap-x-5 text-xs text-white">
                    {
                        languageList.map(item =>  <button class="border-2 border-white-100 p-1 px-3  rounded-lg  ">{item?.name}</button> )
                    }
                    </div>
                    </div>}
            </div>
            <div className="flex justify-end"><  AiOutlineRight className="text-base my-auto text-white cursor-pointer" onClick={()=>setFilter('language')}/></div>
        </div>


        <div className="grid grid-cols-9 mb-10">
            <div className="col-span-8 ">
                 <h2 className="text-white text-base" onClick={()=>setFilter('mood')}>Mood*</h2>

                        {moodList &&  <div className=" mt-2"  onClick={()=>setFilter('mood')}>
                        <div className="col-span-8 text-xs text-white" onClick={()=>setFilter('mood')}> 
                        {  moodList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  "> {moodList?.name} </button> }
                        </div>  
                    </div>}
            </div>
            <div className="flex justify-end"><  AiOutlineRight className="text-base my-auto text-white cursor-pointer" onClick={()=>setFilter('mood')}/></div>
        </div>


      <hr className=" my-6" style={{width:'100%'}}/>

        <div className="flex justify-between mt-10">

            <p className=" text-white " >*Mandatory fields</p>

            <input className="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 focus:outline-none 
            hover:bg-yellow-500 rounded-lg  text-lg px-32 "  value="SUBMIT"   type="submit" />
       </div>
            </div>

        </div>
    </form> 


       {/* /////////////////////////////////////////// */}


        <div className=" pl-10 ">

        <div className=" flex justify-center text-sm" >

            <button className={` ${filter=='genres' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' }    rounded-lg   py-3 w-36 `} onClick={()=>setFilter('genres')}> Genres </button>
            <button className={` ${filter=='language' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' }  rounded-lg  py-3 w-36 mx-10`} onClick={()=>setFilter('language')}> Language</button> 
            <button className={` ${filter=='mood' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' }  rounded-lg    py-3 w-36 `} onClick={()=>setFilter('mood')}>Mood</button>
           
           

            </div>




        <div className="grid grid-cols-2 gap-5 overflow-x-hidden  overflow-y-auto mt-16 text-white  " style={{maxHeight:'62vh'}}>

            {
                filter==='genres' &&  genres.map((sitem, k)=>{

                    const  isthere =  genresList.findIndex(item => item?.id === sitem.id)

                return  <div key={k} className=" col-span-1 flex justify-between items-center " >

                <div className="flex justify-between w-full mr-8">
                    <div>
                      <label for="html">{sitem?.name}</label>
                    </div>
                        <div >
                          <Switch 
                                value={sitem?.id}
                                checked={isthere !== -1 ? true : false}
                                onChange={(_)=>onChangeSwitch(sitem, filter)}
                                {...props}
                        />
                        </div>
                    </div>
                    </div>
                })
            }

            {
                filter==='language' &&  language.map((sitem, k)=>{

                    const  isthere =  languageList.findIndex(item => item?.id === sitem.id)

                return  <div key={k} className=" col-span-1 flex justify-between items-center " >

                <div className="flex justify-between w-full mr-8">
                    <div>
                      <label for="html">{sitem?.name}</label>
                    </div>
                        <div >
                          <Switch 
                                value={sitem?.id}
                                checked={isthere !== -1 ? true : false}
                                onChange={(_)=>onChangeSwitch(sitem, filter)}
                                {...props}
                        />
                        </div>
                    </div>
                    </div>
                })
            }      

       


{
                filter==='mood' &&  mood.map((sitem, k)=>{

                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                         <label for="html">{sitem?.name}</label>
                        </div>
                            <div >
                             <Switch 
                                    value={sitem?.id}
                                    checked={moodList?.id === sitem.id  ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }

     

        

        

        </div>

        {/* <div className="flex justify-end">

        <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 focus:outline-none 
        hover:bg-yellow-500 rounded-lg  text-lg px-16 mt-16  ">    Apply</button>

            </div> */}
        </div>

        </div>
        <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{

            width:"35%",
            height:"38%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.7,
            color:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={thankyouOpen}
        onRequestClose={closeThankModal}
        contentLabel="Example Modal"
      >
        <div className="text-white mt-0 ">

        <div  className=" flex justify-end items-end" >
        <button className=" text-3xl" onClick={closeThankModal}> <HiX/> </button>
        </div>

        <div className=" flex flex-col justify-center items-center " >

        <div className="  bg-white rounded-full p-4">
        {/* < MdStars className="text-7xl text-white"/> */}

         <AiFillStar className="text-5xl text-yellow-500 "/>

        </div>

         <br/>
         <h1 className=" font-semibold text-4xl text-yellow-500 tracking-wide  ">THANK YOU</h1>
        

        <h3  className="text-white  text-sm pt-6 tracking-wide">Your Song Request has been successfully posted.</h3>
        <h3  className="text-white  text-sm pt-3 tracking-wide">Charlie will contact you soon.</h3>
       </div>
       </div>

      </Modal>
        </div>
</FormWrap>



 

    )
}


const FormWrap= styled.div`

input[type="date"] { 
  background: transparent;
  color: white;
  
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}
`










