import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import listenimg from '../../Images/Background/bg21.jpg'
import { Link } from 'react-router-dom'
import Images4 from '../../Images/Background/bg51.png'
import {getMarketPlace,marketSelector,fetchApplicant,deleteMarketPlace , getMarketPayNow} from '../../api/marketPlace'
import {useParams} from 'react-router-dom'
import { useHistory } from 'react-router-dom';
// import {authenticateSelector} from '../../api/authSlice'
import ReactLoading from 'react-loading';
import Accept from './popupAccept';
import Loading from '../../shared/loading' 



export default function Listen() {


    const dispatch = useDispatch()
    const {id} = useParams()
    const {marketplace, applicant, loading}  = useSelector(marketSelector)

    let history = useHistory();

useEffect(()=>{

    dispatch(getMarketPlace(id))
    dispatch(fetchApplicant(id))

}, [dispatch])



// console.log(marketplace);
console.log(applicant);



const onClickDelete = e =>{
    dispatch(deleteMarketPlace(id,history))
}

const onClickPay = (id)=>{
    dispatch(getMarketPayNow(id, marketplace?.id))
}


    return (
        <ListenWrap  className=" pt-20 min-h-screen " style={{background:`linear-gradient(203deg, rgba(255,58,57,1) 0%, rgba(242,42,100,1) 18%,  rgba(228,25,142,1) 33%, rgba(229,26,139,1) 33%, rgba(199,25,184,1) 60%, 
        rgba(130,25,228,1) 89%, rgba(116,16,210,1) 100%)`, backgroundAttachment:'fixed'}} >

{/* <SongWrap className=" pt-20 grid grid-cols-3 min-h-screen" style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} > */}


{
           loading ?  <Loading/> :
             
        <div className=" container mx-auto  w-5/6 grid grid-cols-3 mt-16">
        
       <div className="pb-10">


       <Link to={`/user/marketplace/editsongrequest/${id}`}> <button className={ `text-white  bg-gradient-to-r from-blue-600 to-purple-900 border-white rounded px-6 py-2  ` }> Edit </button></Link>
       <button className={ `text-white  bg-gradient-to-r from-blue-600 to-purple-900 border-white rounded px-3 py-2 mx-10`} onClick={onClickDelete} > Delete </button>


        <h1 className="  text-white text-2xl font-bold pt-8 capitalize">{marketplace?.title}</h1>

        <h2 className="text-white my-1 capitalize ">{marketplace?.description}</h2>

        <div className="pt-6">

        <div className="grid grid-cols-6 my-3"  style={{width:'130%'}}>
                  <h2 className="col-span-1 text-sm my-auto text-white" >Genres :</h2>
                  <div className="col-span-5 grid grid-cols-4  gap-y-2">
                {
                marketplace?.musicgenres.map((btn, i)=>{
                    return <button key={i} style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2 px-0 py-1 rounded-lg ">{btn?.name}</button>
                })
                }
            </div>
            </div>

        
            <div className="grid grid-cols-6  my-3 " style={{width:'130%'}}>
                  <h2 className="col-span-1 text-sm my-auto text-white" >Languages :</h2>
                  <div className="col-span-5 grid grid-cols-4">
                {
                marketplace?.musiclanguages.map((btn, i)=>{
                    return <button key={i} style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2 px-0 py-1 rounded-lg ">{btn?.name}</button>
                })
                }
            </div>
            </div>

            <div className="grid grid-cols-6 my-3"  style={{width:'130%'}}>
                  <h2 className="col-span-1 text-sm my-auto text-white" >Mood :</h2>
                  <div className="col-span-5 grid grid-cols-4">
                  <button  style={{fontSize:"0.7rem", border:"0.3px solid white"}} className=" border-white text-white mx-2  py-1 rounded-lg">{marketplace?.mood?.name}</button>
            </div>
            </div>
   

            </div> 


    
<hr className="my-6 float-left" style={{width:'100%'}}/> 


<h1 className="text-white mt-10 text-lg"> Budget </h1>



<div className="grid grid-cols-3">

<h1 className="text-white mt-3  text-xl"> ${marketplace?.priceTo}</h1>


{/* <div class="col-span-2 pt-2 relative mx-auto text-white">
        <input class="border-2 border-gray-300 bg-black opacity-70 w-96 float-left h-10 px-5 pr-16 rounded-full text-xs focus:outline-none"
          type="search" name="search" placeholder="Search"></input>
<button type="submit" class="searchButton">
              <i class="fa fa-search"></i>
 </button>
 </div> */}
 </div>

<hr className="mt-5 float-left" style={{width:'100%'}}/> 

    {
        applicant.length > 0 ?
<div>
  
<h1 className="text-white mt-10  text-lg mb-4"> Offer Price</h1>
{
    applicant.map((item, i)=>{  
        
       return  <div key={i} className=" grid grid-cols-2 w-96 h-44 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 mr-5" style={{width:'135%'}}>
            <div>
        <h1 className="text-white ml-8 text-md mt-4">offer: ${item?.offer}</h1>
        
        <figure>
            
            <audio
                className=" bg-transparent mt-3 mb-8 ml-10  focus:outline-none"style={{width:'180%'}}
                controls  controlsList="nodownload noplaybackrate "
                src={item?.music}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </figure>


        { item?.status === 'PENDING' &&  <Accept item={item} marketplace={marketplace}/>}

       {  item?.status === 'ACCEPTED' && <div className=" grid grid-cols-2">

            <div class="text-white border-black shadow-sm bg-green-600 font-bold  py-2 text-center ml-8 mt-6  rounded-lg text-sm transform -translate-y-10">
            ACCEPTED</div>

          { !item?.transaction && <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-2 px-2 ml-8 mt-6 focus:outline-none 
          hover:bg-yellow-500 rounded-lg text-sm    transform -translate-y-10" onClick={()=>onClickPay(item?.id)}>
            Pay Now</button>
            }

            { item?.transaction && <div class="text-white text-center border-black shadow-sm bg-yellow-500 font-bold border-0 py-2 px-2 ml-8 mt-6 rounded-lg text-sm  transform -translate-y-10 w-40" >
            PAYMENT PENDING </div>
            }

            </div>}

            </div>
    </div>
})
}
</div>
:<h1 className=" text-white mt-10  text-lg mb-4"> No data found </h1>
}
        </div>


<div className="col-span-2 mt-0   flex justify-end  ">
    <img src={Images4}  alt="bg51.png" style={{height:"70vh", width:"70vh", position:"fixed"}}/>

 
    
</div>
</div>
}
</ListenWrap>
   
    )
}


const ListenWrap = styled.div`


h4{

    font-size:2rem;
    color: green;
    
}

`