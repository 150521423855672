import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './filterlist'
import authReducer from './authSlice';
import commonReducer from './common'
import musicReducer from './music'
import marketReducer from './marketPlace'
import notificationReducer from './notification'
import homeReducer from './home'
import trendingReducer from './trending'
import likesReducer from './likes'


export default configureStore({
  reducer: {
    auth:authReducer,
    filter:filterReducer,
    common:commonReducer,
    notification:notificationReducer,
    home:homeReducer,
    music:musicReducer,
    market:marketReducer,
    trending:trendingReducer,
    likes:likesReducer
  },
});