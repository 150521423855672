import React,{useState,useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Backgroundimage from '../../Images/Background/gradient.jpg'
import {authenticateSelector, updateUserType} from '../../api/authSlice'
import Modal from 'react-modal';
import EditPofile from '../Navbar/editProfile';
import {likesSelector,fetchAllLikes} from '../../api/likes'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {musicSelector, MySongLicence,getMymusic,getMySamplemusic,DeletemySongLicence,MySongLicencerequest} from '../../api/music'


const Myprofile = () => {
  let history = useHistory();


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const {user } = useSelector(authenticateSelector)
  const {likes_list}     = useSelector(likesSelector)
  const dispatch = useDispatch()
  const [filter, setFilter]= useState(user?.aboutus ==='ARTIST' ? 'tracks' : 'likes')
  const {songlicenses,songtrack,samplesong,songstatus , loading} = useSelector(musicSelector)
  
  console.log({status:songstatus});

useEffect(() => {

  dispatch(fetchAllLikes())
  dispatch(MySongLicence())
  dispatch(getMymusic('song'))
  dispatch(getMySamplemusic('sample'))
  dispatch(MySongLicencerequest('pending'))

}, [dispatch])


const onClickUserType = () => {
  dispatch(updateUserType(history))
};


const onClick = (e,id) =>{
  // console.log(id);
  dispatch(DeletemySongLicence(id))

}

console.log(loading);

    return (

<div className=" bg-fixed bg-cover grid grid-cols-3  min-h-screen text-white" style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} >

 

    <div className="  col-span-2 px-2 pt-8 md:px-24 lg:px-8 lg:pt-20 mt-10 ml-28">
      
      <div className="  flex flex-col mb-4 lg:flex-row  ">
        <div className="lg:w-56">
            <img src={user?.photo !== 'https://bidfortask.s3.amazonaws.com/' ? user?.photo : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'} alt="Charlie" className=" h-44 w-44 rounded-full " />
        </div>
          <div className="lg:w-56">
          <h2 className=" mb-2  text-3xl   w-full text-white sm:text-4xl ">
            {user?.name}
              </h2>
              <p className="text-base text-white md:text-lg mb-2">
                {user?.email}
              </p>
              <p className="text-base text-white md:text-lg capitalize">
              {user?.aboutus ==='ARTIST' ? 'Artist' : 'Listener' }
              </p>

        <div className="flex justify-left flex-nowrap  mt-5">
         {user?.aboutus === "ARTIST" &&  <Link to="/user/upload-music">  <button className=" py-2 px-6 bg-gradient-to-r from-blue-600 to-purple-900 border-white border-2  text-white font-light rounded-3xl mr-10">
            Upload
            </button>
            </Link>}
          {user?.aboutus === "ARTIST" && <Link to="/user/record-upload">    <button className=" py-2 px-6 bg-gradient-to-r from-blue-600 to-purple-900 border-white border-2  text-white font-light rounded-3xl mr-10">
            Record
            </button>
            </Link>}

            {user?.aboutus === "LISTENER" &&   <button onClick={onClickUserType} className=" py-2 px-6 bg-gradient-to-r from-blue-600 to-purple-900 border-white border-2  text-white font-light rounded-3xl whitespace-nowrap justify-center flex mr-10">
            Login as Artist
            </button>  }

            <EditPofile/>

         
 
</div>  
          </div>
        </div>
      
      <div className="flex justify-left flex-nowrap py-10">
          <div>
          {user?.aboutus === "ARTIST" &&  <button className={`py-3 px-6  ${filter === 'tracks' ? 'text-black bg-white  font-semibold' : 'text-white bg-black bg-opacity-50'} rounded-xl ml-10 `}   onClick={()=>setFilter('tracks')}>
            Tracks
            </button>}
          </div> 
          <div>
         {user?.aboutus === "ARTIST" &&  <button className= {`py-3 px-6  ${filter === 'sample' ? 'text-black bg-white  font-semibold' : 'text-white bg-black bg-opacity-50'} rounded-xl ml-10`}  onClick={()=>setFilter('sample')}>
          Sample
            </button>}
          </div> 
          <div>
         <button className= {`py-3 px-6  ${filter === 'likes' ? 'text-black bg-white  font-semibold' : 'text-white bg-black bg-opacity-50'} rounded-xl ml-10`} onClick={()=>setFilter('likes')} >
          Likes
            </button>
          </div>

          <div>
          <button className= {`py-3 px-6 whitespace-nowrap justify-center flex ${filter === 'songlicense' ? 'text-black bg-white  font-semibold' : 'text-white bg-black bg-opacity-50'} rounded-xl ml-10`}  onClick={()=>setFilter('songlicense')}>
          Song License
            </button>
          </div>

          {user?.aboutus === "ARTIST" &&
          <button className={` py-3 w-56 whitespace-nowrap  px-3 text-center  ${filter === 'songrequestLicense' ? 'text-black bg-white  font-semibold' : 'text-white bg-black bg-opacity-50'} rounded-xl ml-10`}  onClick={()=>setFilter('songrequestLicense')}>
          Request Song License
            </button>
          }
</div>



     { filter==='likes' &&  <div className="col-span-2 mt-4 text-white  ">
  { 
                likes_list.map((item, i)=>{
  
        
       return  <div key={i} className=" w-full h-36 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 mr-5" style={{width:'80%'}}>
            <div>
        <h1 className="text-white mx-8 text-md pt-4">{item?.title}</h1>
        
        <figure>
            
            <audio
                className=" bg-transparent mt-3 mb-8 ml-10  focus:outline-none"style={{width:'85%'}}
                controls  controlsList="nodownload noplaybackrate "
                src={item?.music}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </figure>

      
            </div>
    </div>
})
}
</div>
}






     { filter==='songlicense' &&   <div className="col-span-2 mt-4 text-white ">
{/* <h1 className="text-white mt-10  text-lg mb-4"> song License</h1> */}
{
                songlicenses.map((item, i)=>{
                  console.log(item);
    
       return  <div key={i} className= " w-full h-44 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 mr-5" style={{width:'80%'}}>
      <div >

        <div className="flex justify-between ">
        <h1 className="text-white mx-8 text-md mt-4">{item?.music?.title ? item?.music?.title : ' '}</h1>

        <h1 className="text-white mx-8 text-md mt-4">{item?.deal_status ? item?.deal_status : ' '}</h1>

        </div>
   
        <figure>
            
            <audio
                className=" bg-transparent mt-3 mb-5 ml-10  focus:outline-none"style={{width:'85%'}}
                controls  controlsList="nodownload noplaybackrate "
                src={item?.music?.music}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </figure>
     
        <div className=" flex justify-end  ">
        <button className="text-white  bg-gradient-to-r from-blue-600 to-purple-900 border-white rounded px-1 py-1 mr-6 " onClick={(e)=>onClick(e,(item?.id))} > Delete </button>
        </div>

        </div>
       
    </div>
})
}

</div>
}





     { filter==='tracks' &&<div className="col-span-2 mt-4 text-white ">
{/* <h1 className="text-white mt-10  text-lg mb-4"> song License</h1> */}
{
songtrack.map((item, i)=>{
                  console.log(item);
  
    
       return  <div key={i} className= " w-full h-36 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 mr-5" style={{width:'80%'}}>
      <div >

        <div className="flex justify-between ">
        <h1 className="text-white mx-8 text-md mt-4">{item?.title ? item?.title : ' '}</h1>

        <button className="text-white mx-8 text-md mt-4 mb-1 bg-yellow-500  rounded-full px-2 py-1 mr-4 w-32" >Request NFT</button>

        </div>
        <figure>
            
            <audio
                className=" bg-transparent mt-3 mb-8 ml-10  focus:outline-none"style={{width:'85%'}}
                controls  controlsList="nodownload noplaybackrate "
                src={item?.music?.music}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </figure>
        </div>
        {/* <div className=" flex justify-end mx-8 text-white  text-md mt-4">
        <h1>offer : {item?.music?.priceTo}</h1>
        </div> */}
      
    </div>
})
}
</div>
}






     { filter==='sample' &&  <div className="col-span-2 mt-4 text-white ">
{/* <h1 className="text-white mt-10  text-lg mb-4"> song License</h1> */}
{
                samplesong.map((item, i)=>{
    
       return  <div key={i} className= " w-full h-36 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 mr-5" style={{width:'80%'}}>
      <div >

        <div className="flex justify-between ">
        <h1 className="text-white mx-8 text-md mt-4">{item?.title ? item?.title : ' '}</h1>

        <h1 className="text-white mx-8 text-md mt-4">{item?.deal_status ? item?.deal_status : ' '}</h1>

        </div>
        <figure>
            
            <audio
                className=" bg-transparent mt-3 mb-8 ml-10  focus:outline-none"style={{width:'85%'}}
                controls  controlsList="nodownload noplaybackrate "
                src={item?.music?.music}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>
        </figure>
        </div>
        {/* <div className=" flex justify-end mx-8 text-white  text-md mt-4">
        <h1>offer : {item?.music?.priceTo}</h1>
        </div> */}
      
    </div>
})
}
</div>
}


{ filter==='songrequestLicense' &&  <div className="col-span-2 mt-4 text-white " style={{width:'150%'}}> 


<div className="grid grid-cols-2" >
  <div><h1 className="mx-8 text-lg">Pending</h1>
  
  {
           songstatus.map((item, i)=>{
             console.log(item);

  return  <div key={i} className= " w-full h-40 bg-black bg-opacity-10 rounded-2xl  shadow-sm mt-5 " style={{width:'80%'}}>
 <div >

   <div className="flex justify-between ">
   <h1 className="text-white mx-8 text-md mt-4">{item?.music?.title ? item?.music?.title : ' '}</h1>
{/* 
   <h1 className="text-white mx-8 text-md mt-4">{item?.deal_status ? item?.deal_status : ' '}</h1> */}

   </div>

   <figure>
       
       <audio
           className=" bg-transparent mt-3 mb-5 ml-10  focus:outline-none"style={{width:'85%'}}
           controls  controlsList="nodownload noplaybackrate "
           src={item?.music?.music}>
           Your browser does not support the
           <code>audio</code> element.
       </audio>
   </figure>


   </div>
  
</div>
})
} 
  
  
  </div>

  
  <div><h1 className="mx-8 text-lg">Accepted</h1></div>
</div>
{/* <h1 className="text-white mt-10  text-lg mb-4"> song License</h1>  */}


</div>
}


      </div> 



        </div>
    )
}

export default Myprofile