import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {logOut} from '../../api/authSlice'
import {Link} from 'react-router-dom'
import {authenticateSelector} from '../../api/authSlice'

export default function Pofile() {
    
    const dispatch = useDispatch()
    const {user } = useSelector(authenticateSelector)
    console.log(user);
  
    return (
        <div>
            <div className=" w-12 mx-12">


            <div class="dropdown"  style={{float:"right"}}> 
            <img src={user?.photo !== 'https://bidfortask.s3.amazonaws.com/' ? user?.photo : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'} alt="Charlie" className="h-12 mr-8  rounded-full " />


            <div class="dropdown-content pt-3 rounded-lg shadow-sm ">
            <div className= "rounded-lg " style={{background:`linear-gradient(30deg, rgba(18,225,234,1) 0%, rgba(157,24,195,1) 30%, rgba(179,0,175,1) 47%, rgba(163,20,196,1) 69%, rgba(45,222,243,1) 98%, 
            rgba(0,211,255,1) 100%)`}} >

                <Link to="/user/myprofile" className="text-white"> <p> My Profile </p> </Link>
                <a className="text-white" href="https://musicapp.cytess.com/about" >About us</a>
                <a className="text-white" href="" onClick={()=>{  dispatch(logOut()) }} >Log out</a>

                </div>
            </div>

            </div>

            </div>

            
        </div>
    )
}

