import React, {useEffect,useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {logOut} from '../../api/authSlice'
import Modal from 'react-modal';
import PhoneInput from 'react-phone-input-2'
import { useForm } from "react-hook-form";
import { AiFillCamera } from "react-icons/ai";
import {authenticateSelector,updateProfile,uploadImage} from '../../api/authSlice'
import Error from '../../shared/error';


export default function EditPofile() {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const {user } = useSelector(authenticateSelector)
    console.log(user);
    const [image,setImagename] = useState(null)
    const [file,setFile] = useState('')
    const [phoneNo, setPhoneNo] = useState(null)
    const [code, setcode] = useState(null)
    const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

      mode:"onTouched"
  });
  



    const dispatch = useDispatch()
  
    function openModal() {
        setIsOpen(true);
      }
  
      function closeModal() {
        setIsOpen(false);
      }
  
      const onChangePhone = (value, data, event, formattedValue) =>{
        setPhoneNo(value.slice(data?.dialCode?.length))
        setcode(data?.dialCode)
  }
  
  console.log(file);
  const onChange = (e) =>{
    setImagename(URL.createObjectURL(e.target.files[0])) 

    const formData = new FormData()
     formData.append('image',e.target.files[0])


    dispatch(uploadImage(formData))

}

      const onSubmit = (value) => {
        const data = {
          name:value.name,
          handle:value.handle,
          email:value.email,
          dob:value.dob,
          gender:value.gender,
          aboutus:value.aboutus,
          acccountIdentifier:phoneNo,
          country_code:`+${code}`
     
        }
    
          dispatch(updateProfile(data))
          setIsOpen(false);
         
      };

  
    return (
       
 <FormWrap>

{/* <button onClick={openModal}>click</button> */}

<button onClick={openModal} className=" w-36 py-2 px-2 bg-gradient-to-r from-blue-600 to-purple-900 border-white border-2  text-white font-light rounded-3xl mr-10" onClick={openModal}>
            Edit Profile
            </button>

      <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{

            width:"40%",
            height:"85%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.6,
            colopr:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className=" flex flex-col justify-center items-center"> 
        <div> 
        <button className="text-white float-right block" onClick={closeModal}>X</button>
        <div className="flex justify-center flex-col items-center mx-9 my-auto" >  


        <div class="flex justify-center mr-28">
   <div class="small-12 medium-2 large-2 columns">
     <div class="circle">
      <img class="profile-pic" src={image ? image : user?.photo !== 'https://bidfortask.s3.amazonaws.com/' ? user?.photo : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'}/> 

     </div>
    
    <div className="flex justify-center" style={{position:'relative'}}>
            <input id="file-upload" type="file" onChange={onChange} class="file-upload" />
            <label for="file-upload" class="custom-file-upload"style={{position:"absolute",top:'105px',left:'45px'}}>
                < AiFillCamera className=" text-white text-4xl" />
            </label>
        </div>
     </div>

</div>




      <div className=" mt-32">
        <form onSubmit={handleSubmit(onSubmit)}>
   
        <input  defaultValue={user?.name} {...register("name", { required: true })} className="py-3 rounded-sm px-2  w-full    text-sm mt-6" type="text" id="fname"  placeholder="Name"></input>
        {errors.name?.type === "required" && <Error msg="required!" />}

        <input defaultValue={user?.handle}  {...register("handle", { required: true })} className="py-3 rounded-sm px-2   w-full   text-sm mt-4" type="text"  id="handle"    placeholder="Handle"></input>
        {errors.name?.type === "required" && <Error msg="required!" />}

        <input defaultValue={user?.email}  {...register("email", { required: true })} className="py-3 rounded-sm px-2   w-full    text-sm mt-4" type="email"  id="email" placeholder="Email" /> 
        {errors.name?.type === "required" && <Error msg="required!" />}


      <div {...register("acccountIdentifier", { required: true })} className= " mt-4   flex w-full  rounded-sm ">


        <PhoneInput id="acccountIdentifier" defaultValue={user?.phone}
        inputProps={{
          name: 'acccountIdentifier',
          required: true,
          autoFocus: true
        }}
        onChange={(value, data, event, formattedValue) => onChangePhone(value, data, event, formattedValue)}
        />

        {errors.name?.type === "required" && <Error msg="required!" />}

      </div>

      <select  {...register("aboutus", { required: true })} placeholder="Tell us about yourself" id="cars" className="py-3 rounded-sm px-2   w-full    text-sm mt-4" defaultValue={user?.aboutus} >
                    <option value="ARTIST">ARTIST</option>
                    <option value="LISTENER">LISTENER</option>
                </select>
                {errors.name?.type === "required" && <Error msg="required!" />}

              <select  defaultValue={user?.gender} {...register("gender", { required: true })} name="gender" id="gender"   className="py-3 rounded-sm px-2   w-full    text-sm mt-4" >
          <option value="male">Male</option>
          <option value="female">Female</option>
          
        </select>
        {errors.name?.type === "required" && <Error msg="required!" />}

      <input defaultValue={user?.dob} {...register("dob", { required: true })} className="py-4 rounded-sm px-2    w-full text-sm mt-4" type="date" id="date"    placeholder="DOB" /> 
      {errors.name?.type === "required" && <Error msg="required!" />}

      <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 px-1 mt-8 focus:outline-none 
      hover:bg-yellow-500 rounded-lg text-lg  w-full  ">
          SAVE CHANGES   </button>
      </form>
     </div>
      </div>
      </div>
</div>
      </Modal>
        </FormWrap>

    
    )
}


const FormWrap= styled.div`
.react-tel-input .form-control {
 
  padding: 10px 14px 10px 60px !important;
}




`
