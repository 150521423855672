import { Mood } from '@mui/icons-material';
import React, {useEffect,useState} from 'react'
import Logo1 from '../../Images/Charlie__Full Logo_White.png'
import image1 from '../../Images/hip-pop.jpeg'
import styled from 'styled-components'

import 'react-phone-input-2/lib/bootstrap.css'
import {Link, useLocation} from 'react-router-dom'
import Search from './search'
import Profile from './profile'




const Nabar = () => {

  const {pathname} = useLocation()

  console.log(pathname);



    return (
      <>  
<NavWrap>     
            <div class="bg-black  z-10 fixed  top-0 sm:m w-full flex justify-between ">

      <div class="px-4 py-4 ml-20 2xl:ml-40 md:px-20 lg:px-4 ">
        <div class="relative flex items-center justify-start ">
        <Link to="/user/home"
            aria-label="charlie"
            title="Charlie"
            class="inline-flex items-center"
          >
            <img src={Logo1} alt="Charlie" className="h-10 mr-8" />
          </Link>
          <ul class="sm:flex items-center hidden space-x-8 lg:flex text-lg font-semibold">

            <li>
            <Link to="/user/home"
                aria-label="Home"
                title="Home"
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-yellow-400"
                style={{color: pathname==='/user/home' ? '#FBBF24' : '#F3F4F6'}}
              >
                Home
              </Link>
            </li>

            <li>
            <Link to="/user/marketplace"
                aria-label="Marketplace"
                title="Marketplace"
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-yellow-400 "
                style={{color: pathname==='/user/marketplace' ? '#FBBF24' : '#F3F4F6'}}
              >
                Marketplace
              </Link>
            </li>
        
            <li>
              <Link to="/user/trending"
                aria-label="Trending"
                title="Trending"
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-yellow-400"
                style={{color: pathname==='/user/trending' ? '#FBBF24' : '#F3F4F6'}}
              >
                Trending
              </Link>
            </li>

            <li>
            <Link to="/user/plans"
                aria-label="Plans"
                title="Plans"
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-yellow-400"
                style={{color: pathname==='/user/plans' ? '#FBBF24' : '#F3F4F6'}}
              >
                Plans
              </Link>
            </li>
          </ul>
          <ul class="sm:flex items-center hidden space-x-8 lg:flex">
            <li>
              
            </li>

          </ul>
          </div>
          </div>


     <div class="col-span-2 flex justify-between items-center relative text-white">

      <Search />
      <Profile/>


 </div>
</div>
        
 </NavWrap>    



 </>
    )
}






export default Nabar
const NavWrap = styled.div`

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #454545, ;
  min-width: 160px;
  box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color:#F99E0B;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #F99E0B;}

  
 `


