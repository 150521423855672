import { useDebounce } from "use-debounce";
import {fetchSearch} from '../../api/home'
import React, {useEffect, useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom';
import {homeSelector} from '../../api/home'

export default function Search() {


    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState(null)
    const [search, setSearch] = useState('')
    let history = useHistory();
 const [debouncedText] = useDebounce(search, 1000);
 const { searchlist } = useSelector(homeSelector)
 

 useEffect(()=>{ 
    if(text==='fill'){
           dispatch(fetchSearch({"keyword":debouncedText}  ))
            history.push(`/user/search`)
     } 
     if(text==='empty'){
        history.push(`/user/home`)
     }

     setLoading(false)
   }, [dispatch, debouncedText])
  
 
  //  useEffect(()=>{     
  //   if(searchlist.length < 1) {
  //     setSearch('')
  //   }
  //  }, [searchlist])


  const onSearch = (e) => {
     setLoading(true)
     setSearch(e.target.value)

     if(e.target.value.length < 1){
        setText('empty')
     }
     else{
        setText('fill')
     }

 }


    return (
        <div>
    <input class="border-2 border-gray-300 bg-black  w-96  h-10 px-5  rounded-full text-xs focus:outline-none"
          type="search" name="search" placeholder="Search" onChange={onSearch}></input>
            
        <button type="submit" class="searchButton">
           <i class="fa fa-search"></i>
        </button> 
 
        </div>
    )
}

