import React from 'react'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import { Link } from 'react-router-dom'
import Fb from '../../Images/Social/FB.png'
import Google from '../../Images/Social/GOOGLE.png' 

const Register = () => {
    return (
                     <div className="min-h-full bg-fixed bg-cover bg-center  pt-8" style={{backgroundImage:`url('${Backgroundimage}')`}}> 

            <section className="text-gray-600 body-font">
           
  <div className="container px-5 py-20 pt-40 mx-auto flex flex-wrap items-center">
  
    <div className="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-8 md:mt-0">
    <p className="flex justify-center text-3xl text-white  mb-5">SIGN UP</p>
<div className="mb-10">
<Link 
  to="/mobile_register"
  className="flex items-center justify-center px-5 py-2 text-black bg-white border shadow-sm rounded-lg group">
  <span className="text-sm font-semibold group-hover:text-gray-800">
  Number
  </span>
  <span className="flex-shrink-0 p-2 ml-4 bg-white border border-white rounded-full">
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-mobile" width="44" height="35" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="7" y="4" width="10" height="16" rx="1" />
  <line x1="11" y1="5" x2="13" y2="5" />
  <line x1="12" y1="17" x2="12" y2="17.01" />
</svg>
  </span>
</Link>
</div>
<div className="mb-10">
<a
  href="#"
  className="flex items-center justify-center px-5 py-2 text-black bg-white border shadow-sm rounded-lg group">
  <span className="text-sm font-semibold group-hover:text-gray-800">
  Facebook
  </span>
  <span className="flex-shrink-0 p-2 ml-4 bg-white border border-white rounded-full">
    <img src={Fb} alt="facebook" className="h-8" />
  </span>
</a>
</div>
<div className="mb-6">
        <a
  href="#"
  className="flex items-center justify-center px-5 py-2 text-black bg-white border shadow-sm rounded-lg group"
>
  <span className="text-sm font-semibold group-hover:text-gray-800">
  Google
  </span>

  <span className="flex-shrink-0 p-2 ml-4 bg-white border border-white rounded-full">
    <img src={Google} alt="facebook" className="h-8" />
  </span>
</a>
</div>
      
      <p className="flex justify-center text-lg py-8 text-white  mb-5">OR</p>

<Link to="/register-page">
<div className= "flex justify-center text-white bg-yellow-400 font-bold border-0 py-4 px-8 border-black mb-10 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg">REGISTER</div>
<hr style={{borderTop: "3px solid white"}}></hr>
</Link>

<Link to="/">
        <p className="flex justify-center text-sm text-white mt-3 mb-5">Already have an account?
        <span className=" px-2 font-semibold"> Sign in!</span>
        </p>
        </Link>
    </div>
  </div>
  
</section>
        </div>
    )
}

export default Register
