import React,{useState, useEffect,useRef} from 'react'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import './form.css'
import {verifyOTP, resendOtp, authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import OtpInput from 'react-otp-input';
import {Link, useHistory,useParams} from 'react-router-dom'

const twoDigits = (num) => String(num).padStart(2, '0')

const INITIAL_COUNT = 10



export default function Otp() {

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)


  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60


 const handleStart = () => {
    setStatus(STATUS.STARTED)
    setSecondsRemaining(INITIAL_COUNT)

    const data = {
      mobile: phone?.mobile,
  }      
    dispatch(resendOtp(data))
  }



 const STATUS = {

  STARTED:'started',
  STOPPED:<b><a type="button" onClick={handleStart} class="text-danger cursor-pointer text-blue-400 pl-3 ">RESEND NOW</a></b>
 }
  
 const [status, setStatus] = useState(STATUS.STARTED)

 useInterval(
  () => {
    if (secondsRemaining > 0)
     {
      setSecondsRemaining(secondsRemaining - 1)
    } else {
      setStatus(STATUS.STOPPED)
    }
  },
  status === STATUS.STARTED ? 1000 : null,
 
)


function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

 
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}


  const { isAuthenticate, phone } = useSelector(authenticateSelector)
  let history = useHistory();
    const dispatch = useDispatch()
    const {id} = useParams()

    const [otp, setOtp] = useState('')
    const [alert, setAlert] = useState(false)


    useEffect(()=>{
        if(isAuthenticate) {
          id == 'forgot-password' ?
          window.location.href = '/user/reset-password'
          :
        window.location.href = '/user/music-preference'
      } else {
          // history.push('/')
      }
    }, [isAuthenticate])
      


    useEffect(()=>{ 
      if(otp.length === 4){
          const data = {
                  mobile: phone.mobile,
                  otp: otp
              }
          dispatch(verifyOTP(data, history))
      }
    }, [otp])
   
 
      const handleChange = (otp) =>{
        return isNaN(otp)? setAlert(true) : setOtp(otp.toString())
      }
   

    return (
        <div style={{backgroundImage: `url(${Backgroundimage})`}} className="h-screen bg-fixed bg-cover bg-center">
            
            <section class="text-gray-600 body-font">
  <div class="container px-5 py-32 pt-40 mx-auto flex flex-wrap items-center">

    <div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
    <p class="flex justify-right font-extrabold tracking-wider text-4xl text-white mb-2">VERIFY THE NUMBER</p>
    <br/>
    <p className="text-white">Sent to : {phone?.mobile}</p>
     
     
      <div class="relative mb-4 py-10">
      {/* <input type="text" id="OTP" name="Otp" placeholder="Enter your OTP here" className="px-4 py-4 rounded-3xl" onChange={onChange}/> */}

          <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                isInputNum={true}
                separator={<span className="mx-1"></span>}
                inputStyle={{border:"1px solid #c7c5c5",
                padding:"0px 1px", 
                fontSize:"2rem",
                height:"42px",
                color:"black",
                borderRadius:"0.2rem"}}
                /> 
                
        </div>
<div className="flex">
        <p class="flex justify-right font-thin tracking-wider text-sm text-white">Didn't receive code?</p> 
      {status == STATUS.STARTED ?  
    <div >

      <span className=" text-indigo-50 px-4 pl-5"  > RESEND OTP in</span> 
        <b class="ml-2 text-indigo-50 text-l" >{twoDigits(minutesToDisplay)}:
      {twoDigits(secondsToDisplay)}</b>
      </div>
      :
      status
      }
    </div>
</div>
  </div>
  
</section>
        </div>
    )
}
