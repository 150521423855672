import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {

  loading:false,
  hasError:false,
  genres:[],
  language:[],
  pitch:[],
  mood:[],
  vocal:[],
  bpm:[],
  instruments:[],
  time_signatures:[],
  category:[],
  tempos:[],
  tempos:[],

}


export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {

    getcommon: state => {
      state.loading = true;
    },

    
    getAll_genres_success: (state, {payload})  =>{
      state.loading = false
      state.genres = payload.data
    },
    
    getAll_language_success: (state, {payload})  =>{
        state.loading = false
        state.language = payload.data
    },

    getAll_pitch_success: (state, {payload})  =>{
      state.loading = false
      state.pitch = payload.data
    },

    getAll_mood_success: (state, {payload})  =>{
      state.loading = false
      state.mood = payload.data
    },

    getAll_vocal_success: (state, {payload})  =>{
      state.loading = false
      state.vocal = payload.data
    },

    getAll_bpm_success: (state, {payload})  =>{
      state.loading = false
      state.bpm = payload.data
    },

    getAll_instruments_success: (state, {payload})  =>{
      state.loading = false
      state.instruments = payload.data
    },

    getAll_time_signatures_success: (state, {payload})  =>{
      state.loading = false
      state.time_signatures = payload.data
    },

    getAll_category_success: (state, {payload})  =>{
      state.loading = false
      state.category = payload.data
    },

    getAll_theme_success: (state, {payload})  =>{
      state.loading = false
      state.theme = payload.data
    },

    getAll_tempos_success: (state, {payload})  =>{
      state.loading = false
      state.tempos = payload.data
    },

    get_common_Failure: (state) => {
      state.loading = false
      state.hasError = true
    },

  },
})


export const { getcommon ,getAll_genres_success,getAll_language_success,getAll_pitch_success,getAll_mood_success,
  getAll_vocal_success,getAll_bpm_success,getAll_instruments_success,getAll_time_signatures_success, get_common_Failure,
  getAll_category_success,getAll_theme_success,getAll_tempos_success } = commonSlice.actions;



export const commonSelector = state => state.common;


export const fetchAllgenres = () => async dispatch => {

  dispatch(getcommon())
 
  try {

   const {data} = await axios.post(keyUri.BACKEND_URI +`/genres`, config)

   console.log(data);
   dispatch(getAll_genres_success(data));
    
  } catch (error) {
 
    dispatch(get_common_Failure())
  }
 };


 export const fetchTimesignatures = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/time-signatures`, config)
     
     console.log(data);
     
     dispatch(getAll_time_signatures_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };

   export const fetchLanguages = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/languages`, config)
     
     console.log(data);
     
     dispatch(getAll_language_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  


   export const fetchBpms = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/bpms`, config)
     
     console.log(data);
     
     dispatch(getAll_bpm_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  
   
   export const fetchMoods = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/moods`, config)
     
     console.log(data);
     
     dispatch(getAll_mood_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  

   export const fetchThemes = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/themes`, config)
     
     console.log(data);
     
     dispatch(getAll_theme_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  

   export const fetchInstruments = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/instruments`, config)
     
     console.log(data);
     
     dispatch(getAll_instruments_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };


   export const fetchTempos = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/tempos`, config)
     
     console.log(data);
     
     dispatch(getAll_tempos_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  
  
   
   export const fetchVocaltypes = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/vocal-types`, config)
     
     console.log(data);
     
     dispatch(getAll_vocal_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };


     
   export const fetchCategory = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/category`, config)
     
     console.log(data);
     
     dispatch(getAll_category_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  
  
   export const fetchPitch = () => async dispatch => {

    dispatch(getcommon())
   
    try {
   console.log(config);
     const {data} = await axios.post(keyUri.BACKEND_URI +`/pitch`, config)
     
     console.log(data);
     
     dispatch(getAll_pitch_success(data));
      
    } catch (error) {
   
   dispatch(get_common_Failure())
      
    }
   };
  
  
  
  
  


export default commonSlice.reducer;
