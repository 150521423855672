import React, {useEffect, useState} from 'react'
import {authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import {homeSelector, fetchAllhomeList} from '../../api/home'
import homeimg from '../../Images/Background/bg21.jpg'
import styled from 'styled-components'
import Image2 from '../../Images/clasical.jpeg'
import { Link } from 'react-router-dom'
import Filter from './homeFilter'
import { IoMdHeart } from 'react-icons/io';
import {LikeMusic} from '../../api/likes'
import { AiOutlinePlus } from "react-icons/ai";
import {commonSelector, fetchAllgenres} from '../../api/common'
import {fetchSearch} from '../../api/home'
import { useHistory } from 'react-router-dom';
import { fetchAlltrendingList, trendingSelector } from '../../api/trending'
import { BsFillPlayCircleFill } from 'react-icons/bs';
import Loading from '../../shared/loading' 


export default function Index() {

const {  user } = useSelector(authenticateSelector)
const { home_list, loading } = useSelector(homeSelector)
const { genres } = useSelector(commonSelector)
const { trending_list } = useSelector(trendingSelector)

let history = useHistory();


const dispatch = useDispatch()

console.log(home_list);
console.log(genres);
console.log(trending_list);


useEffect(()=>{
    dispatch(fetchAllhomeList())
    dispatch(fetchAllgenres()) 
    dispatch(fetchAlltrendingList())
}, [dispatch])



const onSubmit = (item) => {

  const data = {
      gener_ids:[item.id],
      offset: 1,
      instrument_ids:[],
      mood_ids:[],
      vocaltype_ids:[],
      bpms_ids:[],
      pitch_ids:[],
      language_ids:[],
      keyword:'',
      theme_ids:[],
      category_ids:[],
      tempo_ids:[]
  }
      dispatch(fetchSearch(data))
      history.push(`/user/search`)
};


const trending = trending_list[0]?.list.concat(trending_list[1].list, trending_list[2].list) 



    return (
      <SongWrap className=" pt-24 min-h-screen" style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} >

    {
           loading ? <Loading/> :
            
      <div>

      <div className="ml-16 2xl:ml-20 flex flex-row ">
          {user?.aboutus === "ARTIST" && <Link to="/user/upload-music"> <button className="text-white bg-yellow-500  rounded-full px-4 py-2 ml-0 mr-6 m-4 w-44">
                  <div className="flex justify-between items-center p-0 m-0"><AiOutlinePlus className="text-xl" />Upload Music</div>
            </button></Link>}
          <Filter/> 
      </div>


    {/* /////// */}
      
    {/* <div className="container  w-5/6 mx-auto col-span-3"> 
        {
                 home_list.map((item, i)=>{  
                     
                    return <div key={i}> 
                    <div className="flex justify-between items-center text-white mt-5  mr-6"> 
                          <h1 className=" text-2xl  tracking-wider">{item.category}</h1>
                          
                       <Link to= {`home/${item?.id}`}> 
                        {item?.list.length > 5 && <div className="flex justify-end items-center"><p className=" text-lg">View more </p><MdKeyboardArrowRight className="text-xl"/>  </div>}</Link>
                      
                    </div>
                  <div className="grid grid-cols-6 gap-5  overflow-y-auto overflow-x-hidden mt-3 mb-10">
                  {
                 item?.list.map((song, i)=>{   

                 return  <> {i < 6 &&  <div key={i} className=" flex justify-start align-center" >
                  <div>
                  <Link to={`song/${song?.id}`} className=" song h-44 flex justify-center items-center bg-cover bg-center bg-origin-border rounded-2xl w-44 bg-gray-400" 
              
                        style={{backgroundImage:`url('${song?.logo ? `${song?.logo}` : `https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg`}')`, border:'none', color:'#fff'}}>              
                        </Link>

                    <div className="flex justify-around items-center">
                        <p className=" text-center text-white pt-1 " style={{fontSize:'1.07rem'}}>{song.title}</p>
                        <div className=" flex justify-between">
                        <IoMdHeart
                          style={{color:song?.is_liked === 0 ? "white" : "red"}}
                          className="heart p-1 rounded-circle shadow-sm  text-2xl" 
          
                          onClick={()=>dispatch(LikeMusic({"music_id":song?.id})) }
                          /> 
                          <p className="text-white text-xs my-auto">{song?.count}</p>
                       </div>
                    </div>
                        
                    </div>
                </div> }</>
                }
             )}
              </div>

            </div>
            }
       )}
</div> */}


{/* //////Your Music Preference/////// */}

{/* <img src={'https://bidfortask.s3.amazonaws.com/charlis/A Capella-v4.png'} alt="facebook" className="h-40" />

        <div  className=" flex justify-start  align-center " >
  
            <Link to= {`home/`} className="selected h-40 w-40 bg-cover bg-center bg-origin-border rounded-2xl  cursor-pointer text-white hover:text-yellow-400 " 
              style={{backgroundImage:`url('https://bidfortask.s3.amazonaws.com/charlis/A Capella-v4.png')`}}>   
              
              <div  className="flex justify-center items-center bg-black bg-opacity-30 backdrop-filter  w-full h-full rounded-2xl" >
              </div>

           </Link>
          </div>
 */}




<div className='grid grid-cols-5 mt-10 '>

  <div className='ml-16 2xl:ml-20  pr-5 col-span-3 overflow-x-hidden overflow-y-auto'  style={{ height:'72vh' }} > 

  <h1 className="text-lg  tracking-wider text-white mb-6 ">Your Music Preference</h1>

    <div className="col-span-3 grid grid-cols-4 2xl:grid-cols-5 gap-6  pb-12" >
    {
            home_list.map((item, i)=>{   

              const image = item?.gener?.image.replace(/ /g,"%20")
              console.log(item?.gener?.image);
              console.log(image);
              
            return <div key={i} className=" flex justify-start  align-center " >

            <Link to= {`home/${item?.id}`} className="selected h-40 w-40 bg-cover bg-center bg-origin-border rounded-2xl  cursor-pointer text-white hover:text-yellow-400 " 
              style={{backgroundImage:`url(${image})`}}>   
              
              <div  className="flex justify-center items-center bg-black bg-opacity-30 backdrop-filter  w-full h-full rounded-2xl" >
              <h1 className=" px-1 text-center " style={{fontSize:'1.3rem'}}>{ item.category }</h1>
              </div>

           </Link>
          </div>
        })
    }   
    </div> 


{/* /////Other Music////// */}


<h1 className="text-lg  tracking-wider text-white mb-6 ">Other Music</h1>


          <div className="col-span-3 grid grid-cols-4 2xl:grid-cols-5 gap-6 overflow-y-auto overflow-x-hidden mt-3 mb-10" >
          {
            genres.map((item, i)=>{   

              const isthere =  home_list.find(hitem => hitem?.genreId === item.id) 
              const image = item?.image.replace(/ /g,"%20")
              // console.log(item?.image);
              // console.log(image);

              return <>
              { !isthere && <div key={i} className=" flex justify-start  py-3  align-center " >

             <div onClick={(e)=>onSubmit(item)} 
              className="selected h-40 w-40 bg-cover bg-center bg-origin-border rounded-2xl  cursor-pointer text-white hover:text-yellow-400 " 
              style={{backgroundImage:`url(${image})`}}>   
              
              <div  className="flex justify-center items-center bg-black bg-opacity-30 backdrop-filter  w-full h-full rounded-2xl" >
              <h1 className=" px-1 text-center " style={{fontSize:'1.3rem'}}>{item.name}</h1>
              </div>

           </div> 
           
          </div>}
          </>
        })
    }   
    </div> 

    </div>



{/* ////////////////// top songs /////////////// */}

    <div className="pr-16 pl-10 2xl:pr-20 col-span-2 text-white " >

<div className='flex justify-between items-end mr-9 tracking-wider text-white mb-9'>
    <h1 className="text-lg font-medium">Top Songs </h1>
    <Link to={`trending`}> <h1 className="text-sm ">SEE ALL </h1> </Link>
</div>

    <div className=" col-span-3 overflow-x-hidden overflow-y-auto"  style={{ height:'65vh' }} > 
             {
                 
                 trending && trending.map((song, i)=>{
                   
                       return <div key={i}> 
                    
                    <Link to={`song/${song?.id}`} key={i} >
                      <div className=" mb-6  mr-10 cursor-pointer" >
     
                            <div className='flex justify-start align-center items-center '>

                                <div className="selected h-12 w-12  bg-cover bg-center bg-origin-border rounded-full cursor-pointer" 
                                style={{backgroundImage:`url('${song?.logo ? `${song?.logo}` : `https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg`}')`, border: 'none', }}>            
                                </div>

                                <p className=" text-center pt-1 pl-3 capitalize  tracking-wide" 
                                style={{fontSize:'0.95rem', color:'#fff', fontWeight:'500'}} >{song?.title}</p>
                          
                            </div>

                          <div className='flex justify-between align-center items-center '>

                              {/* <div className='w-full flex justify-between items-center' > */}

                                    <p className='text-white text-xs ' >0.00</p>
                                    <div className='w-full ml-3  bg-gray-500 '  >
                                        <hr style={{width:'100%', borderTop: '2px solid #fff'}}/>
                                    </div>  

                                    <BsFillPlayCircleFill className='ml-3 text-3xl text-white ' />
                             {/* </div>   */}

                         </div>

                         </div>
                     </Link>

                           
                 </div>
     
                 }
            )}
     </div>

     

</div>
</div>

  </div>
}
     </SongWrap>

    )
}




const SongWrap =  styled.div`


.heart{

/* font-size:1.1rem;
position:absolute; */
/* bottom:5%;
right:5%; */
/* font-weight:200; */

}

`