import React from 'react'
import Bgimage from '../../Images/Background/price.jpg'
import { Link } from 'react-router-dom'


const Subscription = () => {
    return (
        // <div style={{backgroundImage: `url(${Bgimage})`}} className="h-screen bg-fixed bg-cover bg-center">
           <div className="min-h-screen  bg-fixed bg-cover bg-center  pt-10" style={{backgroundImage:`url('${Bgimage}')`}}> 

             <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 mt-16">
        <h2 className="max-w-3xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
        CHOOSE YOUR SUBSCRIPTION PACKAGE
        </h2>
        <p className="text-base text-white md:text-2xl">
        Choose at least one to proceed
        </p>
      </div>
      <div className="grid max-w-md gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto mt-20">
        
      <div className="relative flex flex-col justify-between p-8  mx-2 transition-shadow duration-300 bg-purple-900 bg-opacity-70 border rounded-3xl shadow-sm sm:items-center hover:shadow border-deep-purple-accent-400" style={{height:'27rem'}}>
          
          <div className="text-center">
            <div className="text-3xl mb-2 text-white font-norma">BASIC</div>
            <hr style={{borderTop: "1px solid white"}} className="mt-5 mb-10"></hr>
            <div className="flex items-end justify-center mt-2 text-white">
              <div className="mr-1 text-5xl font-bold">$5</div>
              <div className="text-white pb-1">/ month</div>
            </div>
            <div className=""> 
            <p className="max-w-xs mt-6 text-sm text-white sm:text-lg sm:text-center sm:max-w-sm sm:mx-auto">
            Save $5
            </p>
            <Link
              to="#"
              className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-yellow-500 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Select
            </Link>
          </div>
          <hr style={{borderTop: "1px solid white"}} className="mt-5 mb-5"></hr>
            <div className="mt-2 ">
              <div className="text-white py-2">Allows you to add 2 songs</div>
              <div className="text-white">Annual - $55 / year</div>
              
            </div>
          </div>
        </div>
        <div className="relative flex flex-col justify-between p-8 transform -translate-y-8 transition-shadow duration-300 bg-purple-900 bg-opacity-70 border rounded-3xl shadow-sm sm:items-center hover:shadow border-deep-purple-accent-400" style={{height:'31rem'}}>
          
          <div className="text-center">
            <div className="text-3xl mb-2 text-yellow-500 font-normal mt-3">INTERMEDIATE</div>
            <hr style={{borderTop: "1px solid white"}} className="mt-6 mb-10"></hr>

            <div className="flex items-end justify-center mt-2 text-white">
              <div className="mr-1 text-5xl font-bold">$10</div>
              <div className="text-white pb-1 ">/ month</div>
            </div>

            <div className=""> 
            <p className="max-w-xs mt-6 text-sm text-white sm:text-lg sm:text-center sm:max-w-sm sm:mx-auto">
            Save $15
            </p>
            <Link
              to="#"
              className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-yellow-500 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Select
            </Link>
          </div>
          <hr style={{borderTop: "1px solid white"}} className="mt-7 mb-5"></hr>
            <div className="mt-2 ">
              <div className="text-white py-2">Allows you to add 4 songs</div>
              <div className="text-white">Annual - $105 / year</div>
              
            </div>
          </div>
        </div>
        <div className="relative flex flex-col justify-between p-8  mx-2 transition-shadow duration-300 bg-purple-900 bg-opacity-70 border rounded-3xl shadow-sm sm:items-center hover:shadow border-deep-purple-accent-400" style={{height:'27rem'}}>
          
          <div className="text-center">
            <div className="text-3xl mb-2 text-white font-normal">PROFESSIONAL</div>
            <hr style={{borderTop: "1px solid white"}} className="mt-5 mb-10"></hr>
            <div className="flex items-end justify-center mt-2 text-white">
              <div className="mr-1 text-5xl font-bold">$15</div>
              <div className="text-white pb-1">/ month</div>
            </div>
            <div className=""> 
            <p className="max-w-xs mt-6 text-sm text-white sm:text-lg sm:text-center sm:max-w-sm sm:mx-auto">
            Save $20
            </p>
            <Link
              to="#"
              className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md bg-yellow-500 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Select
            </Link>
          </div>
          <hr style={{borderTop: "1px solid white"}} className="mt-5 mb-5"></hr>
            <div className="mt-2 ">
              <div className="text-white py-2">Allows you to add 4 songs</div>
              <div className="text-white">Annual - $105 / year</div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
    )
}

export default Subscription
