import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


const initialState = {

    trending_list:[],
    loading:false,
    hasError:false,

}

const loginConfig  = {
    headers: {
      Authorization: token,
    },
  }


export const trendingSlice = createSlice({
  name: 'trending',
  initialState,
  reducers: {

    gettrending: state => {
      state.loading = true;
    },

    getAll_trending_success: (state, {payload})  =>{

        state.loading = false
        state.trending_list = payload.list

    },

    get_success: (state)  =>{

        state.loading = false

    },

    get_trending_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { gettrending ,getAll_trending_success, get_trending_Failure, get_success } = trendingSlice.actions;



export const trendingSelector = state => state.trending;



export const fetchAlltrendingList = () => async dispatch => {
    dispatch(gettrending())
    
    try {
    const {data} = await axios.post(keyUri.BACKEND_URI +`/home`,{}, loginConfig)
       
       dispatch(getAll_trending_success(data));
        
    } catch (error) {
    dispatch(get_trending_Failure())
    error && ErrorMessage(error?.response?.data?.message)
  }
 };



export default trendingSlice.reducer;
