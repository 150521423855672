import React from 'react'
import { toast} from 'react-toastify'

export default function ErrorMessage(message) {
    return (
        <div>
             {    
        toast.error (message, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    }

        </div>
    )
}

