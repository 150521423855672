import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'

const initialState = {

    filter_list:[],
    // sub_list:[],
    loading:false,
    hasError:false,

}


export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {

    getfilter: state => {
      state.loading = true;
    },

getAll_filter_success: (state, {payload})  =>{

    state.loading = false
    state.filter_list = payload.list
    // state.sub_list = payload.data?.list[0]

},


    get_filter_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },
  },
})


export const { getfilter ,getAll_filter_success, get_filter_Failure } = filterSlice.actions;



export const filterSelector = state => state.filter;




export const fetchAllfilter = () => async dispatch => {
console.log('aaaaa');
  dispatch(getfilter())
 
  try { 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/filter-list`, config)
   
   console.log(data);
   
   dispatch(getAll_filter_success(data));
    
  } catch (error) {
 
 dispatch(get_filter_Failure())
    
  }
 };

export default filterSlice.reducer;
