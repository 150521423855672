import React, {useEffect, useState} from 'react'
import {authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import trending, { fetchAlltrendingList,trendingSelector } from '../../api/trending'
import {musicSelector, fetchMusicDetails} from '../../api/music'
import PopupLicense from '../Songs/popuplicense'
import { IoMdHeart } from 'react-icons/io';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import {LikeMusic} from '../../api/likes'



export default function Index() {

const { trending_list } = useSelector(trendingSelector)
const { curr_music } = useSelector(musicSelector)

const [songSelect, setSongSelect] = useState()
const [filter, setFilter] = useState('day')

const {id} =useParams()
const dispatch = useDispatch()


useEffect(()=>{
    dispatch(fetchAlltrendingList())
}, [dispatch])



const onClickSong = (item) =>{
    setSongSelect(item.id)
    dispatch(fetchMusicDetails(item.id))
}


console.log(trending_list);


const trend =trending_list.filter((item, i)=>{   
               
    if(filter === 'day'){
    return item.category === 'Top of the day'
    }
    if(filter === 'month'){
        return item.category === 'Top of the month'
        }
    if(filter === 'year'){
          return item.category === 'Top of the year'
    }
})


const onClickButton = (type)=>{
  setFilter(type)
}

    return (
     
        <div className=" pb-10 bg-fixed bg-cover  min-h-screen  " style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} >

        <div className="  ml-20  pt-24 flex flex-row text-white">
          <button className={` ${filter === 'day'? 'bg-yellow-500 bg-opacity-90' : 'bg-black bg-opacity-40'} px-8 py-2 m-4 ml-0 rounded`} onClick={()=>onClickButton('day')}>Top Songs of the Day</button>
          <button className={` ${filter === 'month'? 'bg-yellow-500 bg-opacity-90' : 'bg-black bg-opacity-40'} px-8 py-2 m-4 ml-0 rounded`} onClick={()=>onClickButton('month')}> Top Songs of the Month</button>
          <button className={` ${filter === 'year'? 'bg-yellow-500 bg-opacity-90' : 'bg-black bg-opacity-40'} px-8 py-2 m-4 ml-0 rounded`}  onClick={()=>onClickButton('year')}>Top Songs of the Year</button>
        </div>


<div className='grid grid-cols-5 divide-x-2'>

   <div className=" ml-20 mr-5 col-span-3  "> 
        {
            
                 trend.map((item, i)=>{   
            
                  return <div key={i}> 
                    
                      {/* <div className="flex justify-between items-center text-white  mr-6"> 
                            <h1 className=" text-2xl  tracking-wider">{item.category}</h1>  
                      </div> */}

                      <div className=" mt-8  overflow-x-hidden overflow-y-auto "  style={{ height:'73vh' }}>
                     {
                      item?.list.map((song, i)=>{   
     
                      return <div key={i} className=" mb-10  mr-16 cursor-pointer" >
     
                            <div className='flex justify-start align-center items-center ' onClick={(e)=>onClickSong(song)}>

                                <div className="selected h-14 w-14  bg-cover bg-center bg-origin-border rounded-full cursor-pointer" 
                                style={{backgroundImage:`url('${song?.logo ? `${song?.logo}` : `https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg`}')`, border: (song?.id === songSelect)?'2px solid #FBBF24' :'none', }}>            
                                </div>

                                <p className=" text-center pt-1 pl-4 capitalize  tracking-wide" 
                                style={{fontSize:'1.15rem', color:(song?.id === songSelect) ? '#FBBF24':'#fff', fontWeight:(song?.id === songSelect)? '600':'500'}} >{song?.title}</p>
                          
                            </div>

                          <div className='flex justify-between align-center items-center  py-2 '>

                              <div className='w-full flex justify-between items-center' onClick={(e)=>onClickSong(song)}>

                                    <p className='text-white text-sm ' >0.00</p>
                                    <div className='w-full ml-3  bg-gray-500 '  >
                                        <hr style={{width:'100%', borderTop: '2px solid #fff'}}/>
                                    </div>  

                                    <BsFillPlayCircleFill className='ml-3 text-4xl text-white ' />
                             </div>  

                            {/* <div className=" flex justify-between items-center ml-8">
                                <p className="text-md text-gray-200">{song?.count}</p>
                                <IoMdHeart
                                style={{color:song?.is_liked === 0 ? "#C1C2C3" : "red"}}
                                className="heart pl-2  text-4xl " 
                                onClick={()=>dispatch(LikeMusic({"music_id":song?.id})) }
                                /> 
                           </div> */}
                         </div>

                       
                     </div>
                     }
                  )}
                   </div>

            </div>

            }
       )}
</div>

<div className="px-10 col-span-2 text-white"> 

{ songSelect && <div> 

    <div class="pt-14 flex justify-items-start">
        
        <div >
             <img alt="feature" class="object-cover object-center h-44 w-44 rounded-3xl" src={curr_music?.logo ? curr_music?.logo : 'https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg'}/>    
        </div>
        
        <div class="ml-5 capitalize ">
           <h2 class=" text-2xl title-font font-bold  mb-3 tracking-normal">{curr_music?.title}</h2>  
           <p class="leading-relaxed text-base">{curr_music?.description}</p>
        </div>

    </div>

    <figure>
                <audio  className=" bg-transparent mt-8 mb-2 w-full" controls  controlsList="nodownload noplaybackrate " src={curr_music?.music}>
                        Your browser does not support the
                        <code>audio</code> element.
                </audio>
    </figure>

        <h4 className="text-lg font-medium pt-4"> Serial Number </h4>          
        <span className=" text-sm font-normal">{curr_music?.sno}</span>  

        <div className="w-full py-5">
        <hr style={{borderTop: "1px solid white"}}></hr>
        </div>


        <h4 className="text-lg font-medium"> Song Details</h4>

        <div className=" flex  justify-between ">
        <div className="py-3">

            <h3 className=" text-sm">Genres</h3>

          <div  className="flex text-black">

                <div className="flex flex-wrap">                           
                                           
            {
                curr_music?.musicgenres.map((item, i)=>{                   
                    return <div key={i}> 
                    <div className="bg-gray-300 text-xs rounded-lg py-2 mt-2 px-2 border-white mr-2">{item?.gener?.name}</div>
                    </div>
                }
            )}
            </div>
          </div>
        </div>


        <div className="py-3 px-2">
        <h3 className=" text-sm">Language</h3>
        <div  className="flex text-black">
            {
                 curr_music?.musiclanguages.map((item, i)=>{                   
                  return <div key={i}> 
                            <div className="bg-gray-300 text-xs rounded-lg py-2 mt-2 px-3 border-white mr-2">{item?.language?.name}</div>
                        </div>
                 }
            )}
            </div>
        </div>

        <div className="py-3 px-2">
        <h3 className=" text-sm">Pitch</h3>
             <div  className="flex text-black mt-2">
                <div className="bg-gray-300 text-xs rounded-lg py-2 px-3 border-white mr-2">{curr_music?.pitch?.name}</div>
            </div>
        </div>

        <div className="py-3 px-2">
        <h3 className=" text-sm">Bpm</h3>
            <div  className="flex text-black mt-2">
                <div className="bg-gray-300 text-xs rounded-lg py-2  px-3 border-white mr-2">{curr_music?.bpms?.name}</div>
            </div>
         </div>

        </div>

     

        <div className="w-full py-5">
        <hr style={{borderTop: "1px solid white"}}></hr>
        </div>

        <a href="https://musicapp.cytess.com/song/policy" target="_blank" className=" text-sm underline pb-4 cursor-pointer ">READ TERMS AND CONDITIONS</a>
        <div className=" flex justify-end mt-6">
        <PopupLicense song={songSelect}/>
        </div>
        </div>
        }
         </div>
        </div>
       </div>

    )
}



