import React from 'react'
import { BrowserRouter as Router, Switch , Route} from "react-router-dom";
import PrivateVendorRoute from './Components/auth/privateRoute'
import Dashboard from './Components'
import LandingPage from './Components/Form/LandingPage';
import ForgotPassword from './Components/Form/ForgotPassword';
import Register from './Components/Form/Register';
import Mobileregistration from './Components/Form/Mobileregistration';
import Otp from './Components/Form/Otp';
import RegisterPage from './Components/Form/RegisterPage';
import ResetPassword from './Components/Form/ResetPassword';


const App = () => {
  return (
  
    <Router>
      <Switch>
        
        <Route exact path="/" component={LandingPage}/>
        <Route exact path="/forgotpassword" component={ForgotPassword}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/mobile_register" component={Mobileregistration}/>
        <Route exact path={`/verify-otp/:id`} component={Otp} /> 

        <Route path={`/register-page`} component={RegisterPage}/>
        {/* <Route path={`/reset-password`} component={ResetPassword}/> */}

        <PrivateVendorRoute path="/user" component={Dashboard} />

      </Switch>
    </Router>
  
  )
}

export default App
