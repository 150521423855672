import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import img from '../../Images/Background/gradient.jpg'
import { useForm } from "react-hook-form";
import axios from 'axios'
import {BsRecordCircle, BsStopCircle} from 'react-icons/bs';
import useRecorder from "./useRecorder";

const MicRecorder = require('mic-recorder-to-mp3');

export default function Upload() {
    
const dispatch = useDispatch()

// let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

// console.log(audioURL);

const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({
    mode:"onTouched"
});


const onSubmit = (value) => {

//     console.log(value);

//     const songdata ={
//         file:value.song[0],
//         type:'MUSIC',
//       }
    
//     //    dispatch(addMusic(data, songdata))
  };


  // const button = document.querySelector('button');
  
  // const recorder = new MicRecorder({
  //   bitRate: 128
  // });

  // button.addEventListener('click', startRecording);


  // function startRecording() {
  //   recorder.start().then(() => {
  //     button.textContent = 'Stop recording';
  //     button.classList.toggle('btn-danger');
  //     button.removeEventListener('click', startRecording);
  //     button.addEventListener('click', stopRecording);
  //   }).catch((e) => {
  //     console.error(e);
  //   });
  // }

  // function stopRecording() {
  //   recorder.stop().getMp3().then(([buffer, blob]) => {
  //     console.log(buffer, blob);
  //     const file = new File(buffer, 'music.mp3', {
  //       type: blob.type,
  //       lastModified: Date.now()
  //     });

  //     const li = document.createElement('li');
  //     const player = new Audio(URL.createObjectURL(file));
  //     player.controls = true;
  //     li.appendChild(player);
  //     document.querySelector('#playlist').appendChild(li);

  //     button.textContent = 'Start recording';
  //     button.classList.toggle('btn-danger');
  //     button.removeEventListener('click', stopRecording);
  //     button.addEventListener('click', startRecording);
  //   }).catch((e) => {
  //     console.error(e);
  //   });
  // }



    return (
        <div>

            <div className=" min-h-screen bg-fixed bg-cover bg-center  pt-28 pb-20 px-20" style={{backgroundImage:`url('${img}')`,backgroundAttachment:'fixed',backgroundPosition:'center'}}>

        <div className=" grid grid-cols-2 gap-x-5 ">
            <p>ssss</p>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                
 
         {/* <div className="flex  items-center mt-3 mr-10">

        <audio src={audioURL} controls  controlsList="nodownload noplaybackrate " className ="w-full" />

        {!isRecording ? <BsRecordCircle className="text-white  text-4xl mx-10" onClick={startRecording}/> :
        <BsStopCircle className="text-red-500 text-4xl mx-10" onClick={stopRecording}/>
        }
  
        </div> */}


        {/* <button class="btn btn-primary" onClick={startRecording}>Start recording</button>
        <button class="btn btn-primary" onClick={stopRecording}>Stop recording</button> */}

{/* <br />
<br />
<br />


<ul id="playlist"></ul>



    </form>  */}

        </div>
        </div>
</div>


    )
}












