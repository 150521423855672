import React,{useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// import './form.css'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import {authenticateSelector, userRegister} from '../../api/authSlice'
import { useForm } from "react-hook-form";
import Error from '../../shared/error';
import styled from 'styled-components'
import ErrorMessage from '../../shared/errorMessage'
import {Link, useHistory,useParams} from 'react-router-dom'


export default function Profile() {

const { isAuthenticate, user } = useSelector(authenticateSelector)
const dispatch = useDispatch()
let history = useHistory();

  const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

    mode:"onTouched"
});


useEffect(()=>{
  
  if(isAuthenticate) {
    window.location.href = '/user/music-preference'

  } else {
      // history.push('/')
  }
}, [isAuthenticate])
  

const onSubmit = (value) => {
  console.log(value);
  const data = {
    name:value.fullname,
    handle:value.handle,
    email:value.email,
    password:value.password,
    confirm_password:value.conformpassword,
    aboutus:value.aboutus
  }

  // var passwordpattern =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  // if(!value.password.match(passwordpattern))
  // {
  //    ErrorMessage("Must have 3 letters, a number and a special character")

  // }
  // else{
    
    dispatch(userRegister(data))
};

// }  

    return (
        <div style={{backgroundImage: `url(${Backgroundimage})`}} className="h-screen bg-fixed bg-cover bg-center ">

            <div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 py-20 flex flex-col md:ml-auto w-full mt-10 md:mt-0 ">
                <p className="flex justify-right text-3xl font-extrabold text-white py-10"> PROFILE </p>
          
 
                <form onSubmit={handleSubmit(onSubmit)}>
                <div class="relative mb-4">
            
            <input {...register("fullname", { required: true })} type="text" id="fullname" autoComplete="off" placeholder="Name" class=" w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}

             <input {...register("handle", { required: true })} type="text" id="handle" placeholder="@handle" class="w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}

            <input {...register("email", { required: true })} type="email" id="email" placeholder="Email" class="w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}

            <input  {...register("password", { required: true })} type="password" id="password" placeholder="Password" class="w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}


            <p className="text-white text-xs mb-4 "> Must be a mix of at least 3 letters, a number, and a special character.</p>

            <input  {...register("conformpassword", { required: true })}  type="password" id="conformpassword" placeholder="Re-type Password"  class="w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}


            <select  {...register("aboutus", { required: true })} placeholder="Tell us about yourself" id="cars" class=" py-3 w-9/12 bg-white rounded border mb-4 border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 px-3 leading-8 transition-colors duration-200 ease-in-out">
              <option style={{display:'none'}}>What is your role?</option>
              <option value="ARTIST">ARTIST</option>
              <option value="LISTENER">LISTENER</option>
          </select>
          {errors.name?.type === "required" && <Error msg="required!" />}


           {/* <div class="group inline-block w-full mb-14">
              <button
                class="outline-none focus:outline-none border px-4 py-3 bg-white rounded flex items-center min-w-32 w-9/12" >
                <span class="pr-1 text-xs flex-1 flex justify-start text-gray-500">Tell us about yourself</span>
                <span>
                 
                </span>
              </button>
              <ul class=" bg-white opacity-50 border rounded-sm transform scale-0 group-hover:scale-100 absolute 
              transition duration-150 ease-in-out origin-top min-w-32 w-9/12 border-white"
              >
                <li class="rounded-sm px-3 py-1 hover:bg-purple-50">ARTIST</li>
                <li class="rounded-sm px-3 py-1 hover:bg-purple-50">LISTENER</li> 
              </ul>
            </div>  */}

          </div>
         
          <input className="text-white bg-yellow-400 font-bold border-0 w-9/12 py-4 px-8 border-black mb-10 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg"  value="NEXT"   type="submit" />

          </form> 
        </div>
        </div>
   
          )

}


const Wrap = styled.div`


`