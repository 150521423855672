import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import img from '../../Images/Background/gradient.jpg'
import { AiOutlineRight } from 'react-icons/ai';
import Switch from "react-switch";
import {BsRecordCircle, BsStopCircle} from 'react-icons/bs';
import styled from 'styled-components'
import { commonSelector, fetchAllgenres, fetchLanguages, fetchPitch, fetchMoods, fetchBpms, fetchInstruments, fetchVocaltypes ,fetchTimesignatures} from '../../api/common'
import {  addRecord} from '../../api/music'
import Error from '../../shared/error';
import { useForm } from "react-hook-form";
import useRecorder from "./useRecorder";
import ErrorMessage from '../../shared/errorMessage'
import { useHistory } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';



export default function Upload() {
    let history = useHistory();

    const dispatch = useDispatch()
    const [filter, setFilter]= useState('genres')
    const { genres,language ,pitch, mood , vocal, bpm, instruments, time_signatures} = useSelector(commonSelector)
    const [genresList, setGenresList]= useState([])
    const [languageList, setLanguageList]= useState([])
    const [instrumentList, setInstrumentList]= useState([])
    const [pitchList, setPitchList]= useState(null)
    const [moodList, setMoodList]= useState(null)
    const [vocalList, setVocalList]= useState(null)
    const [bpmList, setBpmList]= useState(null)
    const [timeList, setTimeList]= useState(null)
    // const [songType, setSongtype]= useState('DEMO')

    let [audioURL, audio, isRecording, startRecording, stopRecording] = useRecorder();




const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({
    mode:"onTouched"
});

useEffect(()=>{
    dispatch(fetchAllgenres())
    dispatch(fetchLanguages())
    dispatch(fetchPitch())
    dispatch(fetchMoods())
    dispatch(fetchBpms())
    dispatch(fetchInstruments())
    dispatch(fetchVocaltypes())
    dispatch(fetchTimesignatures())
}, [dispatch])




const onSubmit = (value) => {

    const genre = genresList?.map(item => item.id)
    const language = languageList?.map(item => item.id)
    const instrument = instrumentList?.map(item => item.id)

    console.log(value);

    const data = {
        title:value.title,
        description:value.description,
        type: 'DEMO',
        geners: genre,
        languages: language,
        instruments: instrument,
        moodId:moodList?.id,
        pitchId:pitchList?.id,
        vocalTypeId:vocalList?.id,
        bpmId:bpmList?.id,
        from_time:timeList?.from_time,
        to_time:timeList?.to_time,
    }
        const songdata ={
            file:audio,
            type:'MUSIC',
        }

      if(genre.length > 0 && language.length > 0 && pitchList && bpmList && audioURL ){
           dispatch(addRecord(data, songdata, history))
        }
        else{
            ErrorMessage('Mandatory field to be filled')    
        }
  };


const props ={
    onColor:"#F99E0B",
    onHandleColor:"#FDFEFE",
    handleDiameter:20,
    uncheckedIcon:false,
    checkedIcon:false,
    boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.6)",
    activeBoxShadow:"0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
    height:25,
    width:60,
    className:"react-switch",
    id:"material-switch"
}



    const onChangeSwitch = (id, filter)=>{

        switch (filter) {
            case 'genres':
                    const  istheregenres =  genresList.findIndex(item => item?.id === id?.id)

                    if (istheregenres !== -1 ){
                        let arr = genresList.filter(item => item?.id !== id?.id ) 
                        setGenresList(arr)

                    } else {
                        setGenresList([...genresList,id])
                    } 
                break;

            case 'language':
                const istherelanguage =  languageList.findIndex(item => item?.id === id?.id)

                    if (istherelanguage !== -1 ){
                        let arr = languageList.filter(item => item?.id !== id?.id ) 
                        setLanguageList(arr)

                    } else {
                        setLanguageList([...languageList,id])
                    } 
                break;

            case 'pitch':
                setPitchList(id)
                break;
            
            case 'mood':
                setMoodList(id)
                break;

            case 'bpm':
                setBpmList(id)
                break;

            case 'instruments':
                const isthereinstruments = instrumentList.findIndex(item => item?.id === id?.id)

                if (isthereinstruments !== -1 ){
                    let arr = instrumentList.filter(item => item?.id !== id?.id ) 
                    setInstrumentList(arr)

                } else {
                    setInstrumentList([...instrumentList,id])
                } 
            break;

            case 'vocal':
                setVocalList(id)
                break;

            case 'time':
                setTimeList(id)
                break;
            }

    }


    return (
        <FormWrap>
            {/* <div className="min-h-screen bg-fixed bg-cover bg-center  pt-20" style={{background:`linear-gradient(30deg, rgba(2,190,230,1) 0%, rgba(9,9,121,1) 11%, rgba(176,4,191,1) 53%, rgba(0,211,255,1) 100%)`, backgroundAttachment:'fixed'}} > */}

            <div className=" min-h-screen bg-fixed bg-cover bg-center  pt-28 pb-20 px-20" style={{backgroundImage:`url('${img}')`,backgroundAttachment:'fixed',backgroundPosition:'center'}}>


        <div className=" grid grid-cols-2 gap-x-5 ">
            
        <form onSubmit={handleSubmit(onSubmit)}>
                
        <div className="pr-10 overflow-x-hidden  overflow-y-auto" style={{ height:'80vh'}}>
        {/* borderRight: " 1px solid white ", */}

            <div>
                
            <h1 className=" text-xl text-white ">Record Track</h1>


        <div className="flex  items-center mt-3 mr-10">
            
        

        <audio src={audioURL} controls  controlsList="nodownload noplaybackrate " className ="w-full" />

        {!isRecording ? <div className="flex justify-center flex-col items-center transform -translate-y-2"> 
                    <p className="text-xs text-gray-300 mb-2">Tap To Record</p><BsRecordCircle className="text-white  text-4xl mx-10" onClick={startRecording}/> 
                 </div>:
                 <div className="flex justify-center flex-col items-center transform -translate-y-2"> 
                   <p className="text-xs text-gray-300 mb-2">Tap To Stop</p><BsStopCircle className="text-red-500 text-4xl mx-10" onClick={stopRecording}/>
                </div>
        }
  

            {/* < AiOutlineCloudUpload  className=" text-5xl text-white   "/> */}

         </div>

        <hr className="my-6" style={{width:'100%'}}/>

        </div>

        <div>
        <h1 className=" text-xl text-white ">Select Song</h1>
                
         <div className="flex  items-center text-sm" >

        <button className={ `text-white ${ `bg-gradient-to-r from-blue-600 to-purple-900 border-white` }  px-6 rounded-full  py-2 m-4 ml-0 border-2  `} > Demo </button>  

        </div> 


         <hr className="my-6" style={{width:'100%'}}/>

        <div className="flex justify-start items-center py-2">

        {/* < SiAddthis className="  items-center text-6xl text-white mr-8  "/>  */}

        <div className="flex flex-col ">

            <input   {...register("title", { required: true }) } id="title" type="text" placeholder="Song Name" className=" placeholder-white text-xl text-white focus:outline-none mb-3 " style={{backgroundColor:"transparent", border:"none"}}/> 
            {errors.name?.type === "required" && <Error msg="required!" />}

            <input  {...register("description", { required: true })} id="description"  type="text" placeholder="Song  Description" className=" placeholder-gray-200 text-base text-white focus:outline-none " style={{backgroundColor:"transparent", border:"none"}}/>
            {errors.name?.type === "required" && <Error msg="required!" />}

        </div>
        </div>



        <hr className=" my-6" style={{width:'100%'}}/>


        <h1 className="text-white text-xl">Song Details </h1>



        <div className="grid grid-cols-5 gap-y-5 pt-8 text-sm text-white">
            
        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('genres')}>Genre*</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('genres')}> 
        {
            genresList.map(item =>  <button class="border-2 border-white-100 p-1 px-3  mr-2 rounded-lg  ">{item?.name}</button> )
        }
         </div>
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('genres')}/>


        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('language')}>Language*</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('language')}> 
        {
            languageList.map(item =>  <button class="border-2 border-white-100 p-1 px-3  mr-2 rounded-lg  ">{item?.name}</button> )
        }
         </div>
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('language')}/>


        <h2 className="my-1 cursor-pointer"  onClick={()=>setFilter('pitch')} >Key*</h2>
        <div className=" col-span-3 text-xs  cursor-pointer"  onClick={()=>setFilter('pitch')}>  {  pitchList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  "> {pitchList?.name} </button> }</div>   
         <  AiOutlineRight className="text-base my-auto cursor-pointer"  onClick={()=>setFilter('pitch')}/>


        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('mood')}>Mood</h2>
        <div className=" col-span-3 text-xs " onClick={()=>setFilter('mood')}>  {  moodList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  "> {moodList?.name} </button> }</div>   
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('mood')}/>


        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('vocal')}>Vocal Type</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('vocal')}>  {  vocalList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  "> {vocalList?.name} </button> }</div>   
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('vocal')}/>


        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('bpm')}>Bpm*</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('bpm')}>  {  bpmList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  "> {bpmList?.name} </button> }</div>   
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('bpm')}/>


        <h2 className="my-1  cursor-pointer" onClick={()=>setFilter('instruments')}>Instruments</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('instruments')}> 
        {
            instrumentList.map(item =>  <button class="border-2 border-white-100 p-1 px-3  mr-2 rounded-lg  ">{item?.name}</button> )
        }
         </div>        
         
         <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('instruments')} />


        <h2 className="my-1 cursor-pointer" onClick={()=>setFilter('time')}>Time Signature</h2>
        <div className=" col-span-3 text-xs  cursor-pointer" onClick={()=>setFilter('time')}>  {  timeList &&  <button class="border-2 border-white-100 p-1 px-3   rounded-lg  ">{timeList?.from_time}-{timeList?.to_time}</button> }</div>   
        <  AiOutlineRight className="text-base my-auto cursor-pointer" onClick={()=>setFilter('time')}/>
        </div> 
 

        <p className="pt-14  text-white " >*Mandatory fields</p>

      <hr className=" my-6" style={{width:'100%'}}/>

        <div className="flex justify-center">

        <input className="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 focus:outline-none 
         hover:bg-yellow-500 rounded-lg  text-lg px-32 mt-8 "  value="UPLOAD"   type="submit" />
            </div>
            </div>

        </div>
    </form> 


       {/* /////////////////////////////////////////// */}


        <div className=" pl-10 ">

        <div className=" grid grid-cols-4 gap-y-6 gap-x-5 text-sm " >

            <button className={` ${filter=='genres' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' }    rounded-lg   py-2  `} onClick={()=>setFilter('genres')}> Genres </button>
            <button className={` ${filter=='language' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' }  rounded-lg  py-2 `} onClick={()=>setFilter('language')}> Language</button>  
            <button className={` ${filter=='pitch' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } rounded-lg  py-2 `} onClick={()=>setFilter('pitch')}> Pitch </button>
            <button className={` ${filter=='mood' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } text-xs rounded-lg    py-2 `} onClick={()=>setFilter('mood')}>Mood</button>
            <button className={` ${filter=='bpm' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } rounded-lg  py-2 `} onClick={()=>setFilter('bpm')}> Bpm </button>
            <button className={` ${filter=='instruments' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } rounded-lg    py-2 `} onClick={()=>setFilter('instruments')}> Instruments </button>
            <button className={` ${filter=='vocal' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } rounded-lg   py-2 `} onClick={()=>setFilter('vocal')}> Vocal Type </button>  
            <button className={` ${filter=='time' ? 'text-black bg-white' : 'text-white bg-black bg-opacity-40' } rounded-lg   py-2 `} onClick={()=>setFilter('time')}>Time Signature</button>

            </div>




        <div className="grid grid-cols-2 gap-5 overflow-x-hidden  overflow-y-auto mt-16 text-white  " style={{maxHeight:'62vh'}}>

            {
                filter==='genres' &&  genres.map((sitem, k)=>{

                    const  isthere =  genresList.findIndex(item => item?.id === sitem.id)

                return  <div key={k} className=" col-span-1 flex justify-between items-center " >

                <div className="flex justify-between w-full mr-8">
                    <div>
                      <label for="html">{sitem?.name}</label>
                    </div>
                        <div >
                          <Switch 
                                value={sitem?.id}
                                checked={isthere !== -1 ? true : false}
                                onChange={(_)=>onChangeSwitch(sitem, filter)}
                                {...props}
                        />
                        </div>
                    </div>
                    </div>
                })
            }

            {
                filter==='language' &&  language.map((sitem, k)=>{

                    const  isthere =  languageList.findIndex(item => item?.id === sitem.id)

                return  <div key={k} className=" col-span-1 flex justify-between items-center " >

                <div className="flex justify-between w-full mr-8">
                    <div>
                      <label for="html">{sitem?.name}</label>
                    </div>
                        <div >
                          <Switch 
                                value={sitem?.id}
                                checked={isthere !== -1 ? true : false}
                                onChange={(_)=>onChangeSwitch(sitem, filter)}
                                {...props}
                        />
                        </div>
                    </div>
                    </div>
                })
            }      

        {
                filter==='pitch' &&  pitch.map((sitem, k)=>{

                return  <div key={k} className=" col-span-1 flex justify-between items-center " >

                <div className="flex justify-between w-full mr-8">
                    <div>
                      <label for="html">{sitem?.name}</label>
                    </div>
                        <div >
                          <Switch 
                                value={sitem?.id}
                                checked={pitchList?.id === sitem.id  ? true : false}
                                onChange={(_)=>onChangeSwitch(sitem, filter)}
                                {...props}
                        />
                        </div>
                    </div>
                    </div>
                })
            }


{
                filter==='mood' &&  mood.map((sitem, k)=>{

                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                          <label for="html">{sitem?.name}</label>
                        </div>
                            <div >
                              <Switch 
                                    value={sitem?.id}
                                    checked={moodList?.id === sitem.id  ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }

        {
                filter==='bpm' &&  bpm.map((sitem, k)=>{

                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                          <label for="html">{sitem?.name}</label>
                        </div>
                            <div >
                              <Switch 
                                    value={sitem?.id}
                                    checked={bpmList?.id === sitem.id  ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }

        {   
                filter==='instruments' &&  instruments.map((sitem, k)=>{

                    const  isthere =  instrumentList.findIndex(item => item?.id === sitem.id)

                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                          <label for="html">{sitem?.name}</label>
                        </div>
                            <div >
                              <Switch 
                                    value={sitem?.id}
                                    checked={isthere !== -1 ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }

        {
                filter==='vocal' &&  vocal.map((sitem, k)=>{

                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                          <label for="html">{sitem?.name}</label>
                        </div>
                            <div >
                              <Switch 
                                    value={sitem?.id}
                                    checked={vocalList?.id === sitem.id  ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }

{
                filter==='time' &&  time_signatures.map((sitem, k)=>{


                    return  <div key={k} className=" col-span-1 flex justify-between items-center " >
    
                    <div className="flex justify-between w-full mr-8">
                        <div>
                          <label for="html">{sitem?.from_time}-{sitem?.to_time}</label>
                        </div>
                            <div >
                              <Switch 
                                    value={sitem?.id}
                                    checked={timeList?.id === sitem.id  ? true : false}
                                    onChange={(_)=>onChangeSwitch(sitem, filter)}
                                    {...props}
                            />
                            </div>
                        </div>
                        </div>
                    })
                }
        </div> 

        {/* <div className="flex justify-end">

        <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-3 focus:outline-none 
        hover:bg-yellow-500 rounded-lg  text-lg px-16 mt-16  ">    Apply</button>

            </div> */}
        </div>

        </div>
        </div>
</FormWrap>


    )
}


const FormWrap= styled.div`


`










