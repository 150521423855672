import React from 'react'
import Modal from 'react-modal';
import { AiFillStar} from 'react-icons/ai';
import Error from '../../shared/error';
import { useForm } from "react-hook-form";
import { HiX} from 'react-icons/hi';
import {sendOffer} from '../../api/music'
import {useDispatch, useSelector} from 'react-redux'
import { getMarketAccept } from '../../api/marketPlace'


export default function Popuplicense({item, marketplace}) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch()

console.log(item);


    function openModal() {
        setIsOpen(true);
      }

      function closeModal() {
        setIsOpen(false);
      }
    


      const onSubmit = () => {
        dispatch(getMarketAccept(item?.id, marketplace?.id ))
        setIsOpen(false);
      };


    return (
        <div>
         <button onClick={openModal} class="text-white border-black shadow-sm bg-green-500 font-bold border-0 py-2 px-3 ml-8 mt-6 focus:outline-none hover:bg-green-600 rounded-lg text-sm    transform -translate-y-10">
            ACCEPT</button>


      <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{
            width:"30%",
            height:"35%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.7,
            colopr:"#f5f5f5",
            border:"none",
            borderRadius:"10px"
          }
      }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >


<div className="text-white mt-4 2xl:mt-5">

<div className=" flex justify-end">
        <button className=" block transform -translate-y-7 text-3xl" onClick={closeModal}><HiX/></button>
</div>

        <div className=" flex flex-col justify-center items-center mx-1 2xl:mx-2 mt-2" >

         <h2 className="text-yellow-400 tracking-wide  text-xl">Price offered is : $ {item?.offer}</h2>
        
        <br/>
         <p className="text-white tracking-wide  text-sm">Please click Accept if you are interested </p>

         <p className="text-white tracking-wide  text-sm">with the offered price</p>

         <button onClick={onSubmit} class="text-white text-base mt-10 border-black shadow-sm bg-green-500 font-bold border-0 py-2 px-7 focus:outline-none hover:bg-green-600 rounded-lg ">
            ACCEPT</button>


    </div>



    </div>
      </Modal>


        </div>
    )
}