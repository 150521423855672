import React, {useEffect, useState} from 'react'
import {authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import {homeSelector, fetchAllhomeList} from '../../api/home'
import {useParams} from 'react-router-dom'
import {musicSelector, fetchMusicDetails} from '../../api/music'
import PopupLicense from '../Songs/popuplicense'
import ReactLoading from 'react-loading';
import { HeartFilled } from '@ant-design/icons';
import {LikeMusic} from '../../api/likes'
import { IoMdHeart } from 'react-icons/io';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import Loading from '../../shared/loading' 



export default function Index() {

const {  user } = useSelector(authenticateSelector)
const { home_list , loading } = useSelector(homeSelector)
const {id} =useParams()
const dispatch = useDispatch()
const { curr_music } = useSelector(musicSelector)


const home = home_list.filter((item, i)=>{ 
    return item?.id == id
})

const [songSelect, setSongSelect] = useState(null)


useEffect(()=>{
    dispatch(fetchAllhomeList())
}, [dispatch])


const onClickSong = (item) =>{
    setSongSelect(item.id)
    dispatch(fetchMusicDetails(item.id))
}


 return (
        <div className=" pt-28 pb-14 bg-fixed bg-cover grid grid-cols-5  divide-x-2  min-h-screen" style={{background:`linear-gradient(203deg, rgba(255,58,57,1) 0%, rgba(242,42,100,1) 18%,  rgba(228,25,142,1) 33%, rgba(229,26,139,1) 33%, rgba(199,25,184,1) 60%, 
        rgba(130,25,228,1) 89%, rgba(116,16,210,1) 100%)`, backgroundAttachment:'fixed'}} >

        {/* <div className="ml-20 mr-5 col-span-3  "> 
             {
                 
                      home.map((item, i)=>{   
                   
                       return <div key={i}> 
                           <div className="flex justify-between items-center text-white  mr-6"> 
                                 <h1 className=" text-2xl  tracking-wider">{item.category}</h1>
                               
                           </div>
     
                       <div className="grid grid-cols-4 gap-1  2xl:grid-cols-5 overflow-y-auto overflow-x-hidden mt-3 mb-10">
                     {
                      item?.list.map((song, i)=>{   
     
                      return <div key={i} className=" flex justify-start align-center" >
     
                       <div onClick={(e)=>onClickSong(song)} >
                              <div className="selected h-40 flex justify-center items-center bg-cover bg-center bg-origin-border rounded-2xl w-40 bg-gray-400 " 
                               style={{backgroundImage:`url('${song?.logo ? `${song?.logo}` : `https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg`}')`, border: (song?.id === songSelect)?'2px solid #fff' :'none', }}>            
                             </div>
                             <p className=" text-center pt-1" style={{fontSize:'1.07rem', color:(song?.id === songSelect)? '#fff':'#fff'}}>{song?.title}</p>
                             
                       </div>         
                         
                     </div>
                     }
                  )}
                   </div>
     
                 </div>
     
                 }
            )}
     </div> */}

     <div className="ml-32 mr-4 col-span-3 " > 
             {
                 
                      home.map((item, i)=>{
                   
                       return <div key={i}> 
                           <div className="flex justify-between items-center text-white  mr-6"> 
                                 <h1 className=" text-2xl  tracking-wider">{item.category}</h1>
                               
                           </div>
     
                       <div className=" mt-8  overflow-x-hidden overflow-y-auto "  style={{ height:'73vh' }}>
                     {
                      item?.list.map((song, i)=>{   
     
                      return <div key={i} className=" mb-10  mr-16 cursor-pointer" >
     
                            <div className='flex justify-start align-center items-center ' onClick={(e)=>onClickSong(song)}>

                                <div className="selected h-14 w-14  bg-cover bg-center bg-origin-border rounded-full cursor-pointer" 
                                style={{backgroundImage:`url('${song?.logo ? `${song?.logo}` : `https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg`}')`, border: (song?.id === songSelect)?'2px solid #FBBF24' :'none', }}>            
                                </div>

                                <p className=" text-center pt-1 pl-4 capitalize  tracking-wide" 
                                style={{fontSize:'1.15rem', color:(song?.id === songSelect) ? '#FBBF24':'#fff', fontWeight:(song?.id === songSelect)? '600':'500'}} >{song?.title}</p>
                          
                            </div>

                          <div className='flex justify-between align-center items-center  py-2 '>

                              <div className='w-full flex justify-between items-center' onClick={(e)=>onClickSong(song)}>

                                    <p className='text-white text-sm ' >0.00</p>
                                    <div className='w-full ml-3  bg-gray-500 '  >
                                        <hr style={{width:'100%', borderTop: '2px solid #fff'}}/>
                                    </div>  

                                    <BsFillPlayCircleFill className='ml-3 text-4xl text-white ' />
                             </div>  

                            <div className=" flex justify-between items-center ml-8">
                                <p className="text-md text-gray-200">{song?.count}</p>
                                <IoMdHeart
                                style={{color:song?.is_liked === 0 ? "#C1C2C3" : "red"}}
                                className="heart pl-2  text-4xl " 
                                onClick={()=>dispatch(LikeMusic({"music_id":song?.id})) }
                                /> 
                           </div>
                         </div>

                       
                     </div>
                     }
                  )}
                   </div>
     
                 </div>
     
                 }
            )}
     </div>
     
     <div className="px-10 col-span-2 text-white"> 
     
     { songSelect && <div> 
     
         <div class="pt-14 flex justify-items-start">
             
             <div >
                  <img alt="feature" class="object-cover object-center h-44 w-44 rounded-3xl" src={curr_music?.logo ? curr_music?.logo : 'https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg'}/>    
             </div>
             
             <div class="ml-5 capitalize ">
                <h2 class=" text-2xl title-font font-bold  mb-3 tracking-normal">{curr_music?.title}</h2>  
                <p class="leading-relaxed text-base">{curr_music?.description}</p>
             </div>
     
         </div>
     
         <figure>
                     <audio  className=" bg-transparent mt-8 mb-2 w-full"  controlsList="nodownload noplaybackrate" controls src={curr_music?.music}>
                             Your browser does not support the
                             <code>audio</code> element.
                     </audio>
         </figure>
     
             <h4 className="text-lg font-medium pt-4"> Serial Number </h4>          
             <span className=" text-sm font-normal">{curr_music?.sno}</span>  
     
             <div className="w-full py-5">
             <hr style={{borderTop: "1px solid white"}}></hr>
             </div>
     
     
             <h4 className="text-lg font-medium"> Song Details</h4>
     
             <div className=" flex  justify-between ">
             <div className="py-3">
     
                 <h3 className=" text-sm">Genres</h3>
     
               <div  className="flex text-black">
     
                     <div className="flex flex-wrap">                           
                                                
                 {
                     curr_music?.musicgenres.map((item, i)=>{                   
                         return <div key={i}> 
                         <div className="bg-gray-300 text-xs rounded-lg py-2 mt-2 px-2 border-white mr-2">{item?.gener?.name}</div>
                         </div>
                     }
                 )}
                 </div>
               </div>
             </div>
     
     
             <div className="py-3 px-2">
             <h3 className=" text-sm">Language</h3>
             <div  className="flex text-black">
                 {
                      curr_music?.musiclanguages.map((item, i)=>{                   
                       return <div key={i}> 
                                 <div className="bg-gray-300 text-xs rounded-lg py-2 mt-2 px-3 border-white mr-2">{item?.language?.name}</div>
                             </div>
                      }
                 )}
                 </div>
             </div>
     
             <div className="py-3 px-2">
             <h3 className=" text-sm">Key</h3>
                  <div  className="flex text-black mt-2">
                     <div className="bg-gray-300 text-xs rounded-lg py-2 px-3 border-white mr-2">{curr_music?.pitch?.name}</div>
                 </div>
             </div>
     
             <div className="py-3 px-2">
             <h3 className=" text-sm">Bpm</h3>
                 <div  className="flex text-black mt-2">
                     <div className="bg-gray-300 text-xs rounded-lg py-2  px-3 border-white mr-2">{curr_music?.bpms?.name}</div>
                 </div>
              </div>
     
             </div>
     
          
     
             <div className="w-full py-5">
             <hr style={{borderTop: "1px solid white"}}></hr>
             </div>
     
             <a href="https://musicapp.cytess.com/song/policy" target="_blank" className=" text-sm underline pb-4 cursor-pointer ">READ TERMS AND CONDITIONS</a>
             <div className=" flex justify-end mt-6">
             <PopupLicense song={songSelect}/>
             </div>
             </div>
             }
              </div>
             </div>
         )
}



