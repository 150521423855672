import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'
import {fetchAllhomeList} from '../api/home'
import {fetchAlltrendingList} from '../api/trending'

const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


const initialState = {

    likes_list:[],
    loading:false,
    hasError:false,

}

const loginConfig  = {
    headers: {
      Authorization: token,
    },
  }


export const likesSlice = createSlice({
  name: 'likes',
  initialState,
  reducers: {

    getlikes: state => {
      state.loading = true;
    },


    getAll_likes_success: (state, {payload})  =>{
      console.log(payload);

        state.loading = false
        state.likes_list = payload.list

    },

    get_success: (state)  =>{

        state.loading = false

    },

    get_likes_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { getlikes ,getAll_likes_success, get_success, get_likes_Failure } = likesSlice.actions;



export const likesSelector = state => state.likes;



export const LikeMusic = (music_id) => async dispatch => {
 
    dispatch(getlikes())
    console.log("test");
    try {
    const {data} = await axios.post(keyUri.BACKEND_URI +`/likes-music`,music_id, loginConfig)
     console.log(data);
      // window.location.reload()
       dispatch(fetchAllhomeList());
       dispatch(fetchAlltrendingList())

    } catch (error) {
    
    dispatch(get_likes_Failure())
    error && ErrorMessage(error?.response?.data?.message)

  }
 };



 export const fetchAllLikes = () => async dispatch => {

    dispatch(getlikes())
   
        try {
            
            const {data} = await axios.get(keyUri.BACKEND_URI +`/liked-music`, loginConfig)  
            // console.log(data);
            // data && SuccessMessage(data.message)
        
         dispatch(getAll_likes_success(data));
        
        } catch (error) {
    
    dispatch(get_likes_Failure())
    error && ErrorMessage(error?.response?.data?.message)

        }
   };
  



export default likesSlice.reducer;
