import React,{useState} from 'react'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import PhoneInput from 'react-phone-input-2'
import {fetchMobileRegister,sendOTP, authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
// import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import 'react-phone-input-2/lib/bootstrap.css'
import './form.css'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';


export default function MobileLogin() {

  const dispatch = useDispatch()
  let history = useHistory();
  const [phoneNo, setPhoneNo] = useState(null)
  const [code, setcode] = useState(null)


const onSubmit = () => {
  const data = {
    mobile:phoneNo,
    country_code:`+${code}`,
}
  dispatch(fetchMobileRegister(data, history))
};

  const onChangePhone = (value, data, event, formattedValue) =>{
      setPhoneNo(value.slice(data?.dialCode?.length))
      setcode(data?.dialCode)
}


    return (
        <div style={{backgroundImage: `url(${Backgroundimage})`}} className="h-screen bg-fixed bg-cover bg-center">
            
            <section class="text-gray-600 body-font">
      <div class="container px-5 py-32 pt-40 mx-auto flex flex-wrap items-center">
        <div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
        <p class="flex justify-right font-extrabold tracking-wider text-4xl text-white mb-2">CREATE AN ACCOUNT WITH NUMBER</p>
    
      <div class="relative mb-4 py-10">
               
        <PhoneInput id="phone"
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true
        }}
         onChange={(value, data, event, formattedValue) => onChangePhone(value, data, event, formattedValue)}
      />





<p class="flex justify-right font-thin tracking-wider text-sm text-white py-1 ">By continuing, you agree to the Terms of Use and Privacy Policy.</p>

      </div>
      <button class="text-white border-black shadow-sm bg-yellow-400 font-bold border-0 py-4 px-8 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg" onClick={onSubmit}>SEND CODE</button>
     <Link to="/">
      <p class="flex justify-right font-thin tracking-wider text-sm text-white py-2 ">Trouble logging in? Get Help</p>  
      </Link>
    </div>

  </div>
  
  
</section>
        </div>
    )
}

