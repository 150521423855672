const keyUri = {

    BACKEND_URI:"https://musicapp.cytess.com/api"
  
}
const config = {
    headers: {
        Accept: "application/json",
        Host: "<calculated when request is sent>",
        Connection: "keep-alive",
    }
  };

  
export  { keyUri,  config }

export default keyUri