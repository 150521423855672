import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


const initialState = {

    notification_list:[],
    loading:false,
    hasError:false,

}

const loginConfig  = {
    headers: {
      Authorization: token,
    },
  }


export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {

    getnotification: state => {
      state.loading = true;
    },

getAll_notification_success: (state, {payload})  =>{

    state.loading = false
    state.notification_list = payload.data

},

    get_success: (state)  =>{

        state.loading = false

    },

    get_notification_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { getnotification ,getAll_notification_success, get_notification_Failure, get_success } = notificationSlice.actions;



export const notificationSelector = state => state.notification;



export const fetchAllnotification = () => async dispatch => {

    dispatch(getnotification())
    
    try {
    const {data} = await axios.get(keyUri.BACKEND_URI +`/get-notification`, loginConfig)
    
    console.log(data);
    
       dispatch(getAll_notification_success(data));
        
    } catch (error) {
    
    dispatch(get_notification_Failure())
    error && ErrorMessage(error?.response?.data?.message)

  }
 };



 export const updatenotification = (notifydata,history) => async dispatch => {

    dispatch(getnotification())
   
        try {
            console.log('notifydata');

            const {data} = await axios.put(keyUri.BACKEND_URI +`/update-notification`,notifydata, loginConfig)
            
            // console.log(data);
            // data && SuccessMessage(data.message)

        
         dispatch(get_success());
        
        } catch (error) {
    
    dispatch(get_notification_Failure())
    error && ErrorMessage(error?.response?.data?.message)

        }
   };
  



export default notificationSlice.reducer;
