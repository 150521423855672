import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import SuccessMessage from '../shared/successMessage'
import { useHistory } from 'react-router-dom';
const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null



const initialState = {

    market_list:[],
    loading:false,
    hasError:false,
    marketplace:null,
    applicant:[]
}


export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {

    getmarket: state => {
      state.loading = true;
    },

  getAll_market_success: (state, {payload})  =>{

      state.loading = false
      state.market_list = payload.list
  },

  
  get_marketplace_success: (state, {payload})  =>{
    console.log(payload);

    state.loading = false

    state.marketplace = payload.data
},

    get_applicant_success: (state, {payload})  =>{
      console.log(payload);

      state.loading = false
      state.applicant = payload.list
    },


    get_success: (state)  =>{

      state.loading = false
  },

    get_market_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { getmarket ,getAll_market_success, get_market_Failure,get_marketplace_success,get_applicant_success, get_success } = marketSlice.actions;



export const marketSelector = state => state.market;

const loginConfig  = {
  headers: {
    Authorization: token,
  },
}


export const fetchMarketPlaceRequestList = (values) => async dispatch => {
  dispatch(getmarket())
 
  try {
console.log(values);
   const {data} = await axios.post(keyUri.BACKEND_URI +`/market-places`,values, loginConfig)
   
   console.log(data);
   
   dispatch(getAll_market_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };


 export const updateMarketPlace = (values,history) => async dispatch => {
   console.log(values);

  dispatch(getmarket())
 
  try {

   const {data} = await axios.put(keyUri.BACKEND_URI +`/market-place`,values, loginConfig)
   
   console.log({K:data});
   data && SuccessMessage(data?.message)
  //  dispatch(get_marketplace_success(data?.message));
   data && history.push(`/user/marketplace`)
  } catch (error) {
    console.log(error);
    dispatch(get_market_Failure())
 dispatch(get_market_Failure())
    
  }
 };


 export const createMarketPlace = (values) => async dispatch => {

  dispatch(getmarket())
 
  try {

    console.log(values);

   const {data} = await axios.post(keyUri.BACKEND_URI +`/market-place`,values, loginConfig)
   
   console.log(data);

  //  dispatch(getAll_market_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };



 export const getMarketPlace = (id) => async dispatch => {
   console.log(id);

  dispatch(getmarket())
 
  try {

   const {data} = await axios.get(keyUri.BACKEND_URI +`/market-place?id=${id}`, loginConfig)
   
   console.log(data);
   
   dispatch(get_marketplace_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };
  

 export const getMarketAccept = (id, request) => async dispatch => {
  console.log(id);

 dispatch(getmarket())

 try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/accept-offer?id=${id}`, loginConfig)
  
  data && dispatch(fetchApplicant(request))
  
  dispatch(get_success());
   
 } catch (error) {

dispatch(get_market_Failure())
   
 }
};
 
export const getMarketPayNow = (id , request) => async dispatch => {

 dispatch(getmarket())

 try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/pay-now?id=${id}`, loginConfig)
  
  data && dispatch(fetchApplicant(request))
  data && SuccessMessage(data?.message)

  dispatch(get_success());
   
 } catch (error) {

dispatch(get_market_Failure())
   
 }
};
 


export const deleteMarketPlace = (id,history) => async dispatch => {
  console.log(history);

 dispatch(getmarket())

 try {

  const {data} = await axios.delete(keyUri.BACKEND_URI +`/market-place?id=${id}`, loginConfig)

  data && SuccessMessage(data?.message)
  data && history.push(`/user/marketplace`)
  
  // dispatch(getAll_market_success());
   
 } catch (error) {

dispatch(get_market_Failure())
   
 }
};
 
 
export const getMyPostRequest = (id) => async dispatch => {
  console.log(id);

 dispatch(getmarket())

 try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/my-post-request?offset=${id}`, loginConfig)
  
  console.log(data);
  
  dispatch(getAll_market_success(data));
   
 } catch (error) {

dispatch(get_market_Failure())
   
 }
};


    export const fetchApplicant = (id) => async dispatch => {
      // console.log(id);

    dispatch(getmarket())

    try {

      const {data} = await axios.get(keyUri.BACKEND_URI +`/get-applicant?id=${id}`, loginConfig)
      
      console.log({K:data});
      
      dispatch(get_applicant_success(data));
      
    } catch (error) {

    dispatch(get_market_Failure())
      
    }
  };
    
 

  
export const ApplyMarketPlace = (values, history, bodydata) => async dispatch => {

 dispatch(getmarket())

 try {

  const {data} = await axios.post(keyUri.BACKEND_URI +`/apply-market-place`, values, loginConfig)

  data && dispatch(fetchMarketPlaceRequestList(bodydata))
  data && SuccessMessage( data.message)
  data && history.push(`/user/marketplace`)
  
   
 } catch (error) {

  console.log(error);
dispatch(get_market_Failure())
   
 }
};


  
export const updateOffer = (values) => async dispatch => {
  
 

  dispatch(getmarket())
 
  try {
 
   const {data} = await axios.post(keyUri.BACKEND_URI +`/update-offer`, values,loginConfig)
   
   console.log(data);
   
   dispatch(getAll_market_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };


 export const getMyOffer = (values,id) => async dispatch => {
 

  dispatch(getmarket())
 
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/offer?id=${id}`, values,loginConfig)
   
   console.log(data);
   
   dispatch(getAll_market_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };


 export const playMyMusic = (id) => async dispatch => {
 console.log(id);

  dispatch(getmarket())
 
  try {
 
   const {data} = await axios.get(keyUri.BACKEND_URI +`/play-music?id=${id}`,loginConfig)
   
   console.log(data);
   
   dispatch(getAll_market_success(data));
    
  } catch (error) {
 
 dispatch(get_market_Failure())
    
  }
 };
 
 

  
  
 


export default marketSlice.reducer;
