import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


const initialState = {

    home_list:[],
    loading:false,
    hasError:false,
    searchlist:[]

}

const loginConfig  = {
    headers: {
      Authorization: token,
    },
  }


export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {

    gethome: state => {
      state.loading = true;
    },

    getAll_home_success: (state, {payload})  =>{

        state.loading = false
        state.home_list = payload.list

    },
    
    getAll_search_success: (state, {payload})  =>{
      // console.log(payload);

      state.loading = false
      state.searchlist = payload.list

  },

    get_success: (state)  =>{

        state.loading = false

    },

    get_home_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { gethome ,getAll_home_success, get_home_Failure, get_success,getAll_search_success } = homeSlice.actions;



export const homeSelector = state => state.home;



export const fetchAllhomeList = () => async dispatch => {
    dispatch(gethome())
        try {
    const {data} = await axios.post(keyUri.BACKEND_URI +`/home?type=HOME`,{}, loginConfig)
       
       dispatch(getAll_home_success(data));
        
    } catch (error) {
    dispatch(get_home_Failure())
    error && ErrorMessage(error?.response?.data?.message)
  }
 };


 export const fetchSearch = (keyword) => async dispatch =>{
  dispatch(gethome())
  try{

        const {data} = await axios.post(keyUri.BACKEND_URI +`/search-music`,keyword, loginConfig)
        data && dispatch(getAll_search_success(data));

        }catch(error){

      dispatch(get_home_Failure())
      error && ErrorMessage(error?.response?.data?.message)
    }

 }


export default homeSlice.reducer;
