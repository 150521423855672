import React, {useEffect, useState} from 'react'
import "./style.css"
import Backgroundimage from '../../Images/Background/gradient.jpg'
import imagemen from '../../Images/Artist/Adobe1.jpg'
import {musicSelector, fetchMusicDetails} from '../../api/music'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory,useParams} from 'react-router-dom'
import Image2 from '../../Images/clasical.jpeg'
import PopupLicense from './popuplicense'
import { HeartFilled } from '@ant-design/icons';
import {LikeMusic} from '../../api/likes'
import ReactLoading from 'react-loading';
import styled from 'styled-components'
import Loading from '../../shared/loading' 


export default function Songdetail(){

    const dispatch = useDispatch()
    const {id} = useParams()
    console.log(id);

    const { curr_music , loading} = useSelector(musicSelector)

    useEffect(()=>{
        dispatch(fetchMusicDetails(id))    
    }, [dispatch])
    



    return (
        <SongWrap className="min-h-screen  bg-fixed bg-cover pt-10 text-white bg-center "style={{background:`linear-gradient(203deg, rgba(255,58,57,1) 0%, rgba(242,42,100,1) 18%,  rgba(228,25,142,1) 33%, rgba(229,26,139,1) 33%, rgba(199,25,184,1) 60%, 
        rgba(130,25,228,1) 89%, rgba(116,16,210,1) 100%)`, backgroundAttachment:'fixed'}} >

{
        loading ? <Loading/> :
         
        <>
        
  <div class="container mx-auto w-5/6 pt-28 pb-10  lg:grid lg:grid-cols-4 flex flex-wrap gap-4">
      
    <div class=" w-full mb-10 lg:mb-0 rounded-3xl overflow-hidden bg-gray-400">
      <img alt="image" class="object-cover object-center h-full w-full" src={curr_music?.logo ?curr_music?.logo : 'https://bidfortask.s3.amazonaws.com/user-image-music/6CZOZ2SwrDra9H0oyxD37mh4SxfTgbiiYYBUa830.jpg'}/>    
    </div>

    <div class="flex flex-col flex-wrap lg:pb-6  col-span-3 lg:pl-12 lg:text-left text-center">

        <div  className="mr-24"> 
            <div className="flex justify-between mb-2"> 
                    <h2 class="text-white text-2xl title-font font-bold  mb-3 tracking-normal	">{curr_music?.title}</h2>  
                    <h4 className="text-xl font-semibold "> Serial Number : <span className=" text-base font-normal">{curr_music?.sno}</span></h4>            
            </div>
            

            <p class="leading-relaxed text-base">{curr_music?.description}</p>

            <figure>
                <audio  className=" mt-8 mb-2 w-full" controls controlsList="nodownload noplaybackrate " src={curr_music?.music}>
                        Your browser does not support the
                        <code>audio</code> element.
                </audio>

                 </figure>

                 
        </div>

        <div className="w-full py-9">
        <hr style={{borderTop: "1px solid white"}}></hr>
        </div>

        <h4 className="text-xl font-semibold"> Song Details</h4>
        <div className=" flex  justify-between mr-24">

        <div className="py-4">

            <h3>Genres</h3>

          <div  className="flex text-black">

                <div className="flex flex-wrap">
            {
                curr_music?.musicgenres.map((item, i)=>{                   
                    return <div key={i}> 
                    <div className="bg-gray-300 text-sm rounded-lg py-2 mt-4 px-3 border-white mr-2">{item?.gener?.name}</div>
                    </div>
                }
            )}
            </div>
          </div>
        </div>


        <div className="py-4 px-2">
        <h3>Language</h3>
        <div  className="flex text-black">
            {
                 curr_music?.musiclanguages.map((item, i)=>{                   
                  return <div key={i}> 
                            <div className="bg-gray-300 text-sm rounded-lg py-2 mt-4 px-3 border-white mr-2">{item?.language?.name}</div>
                        </div>
                 }
            )}
            </div>
        </div>

        <div className="py-4 px-2">
        <h3>Key</h3>
             <div  className="flex text-black mt-4">
                <div className="bg-gray-300 text-sm rounded-lg py-2 px-3 border-white mr-2">{curr_music?.pitch?.name}</div>
            </div>
        </div>

        <div className="py-4 px-2">
        <h3>Bpm</h3>
            <div  className="flex text-black mt-4">
                <div className="bg-gray-300 text-sm rounded-lg py-2  px-3 border-white mr-2">{curr_music?.bpms?.name}</div>
            </div>
         </div>

        </div>

     

        <div className="w-full py-9">
        <hr style={{borderTop: "1px solid white"}}></hr>
        </div>
  
    </div>
  </div>

   <div className="flex justify-end py-6 w-full pr-16">
        <div className="flex flex-col  items-center" >
            <a href="https://musicapp.cytess.com/song/policy" target="_blank" className=" text-base underline pb-2 cursor-pointer ">READ TERMS AND CONDITIONS</a>
           {/* <button class="text-white border-black shadow-sm bg-yellow-500 font-bold border-0 py-4 px-28 focus:outline-none hover:bg-yellow-500 rounded-lg text-lg">LICENSE THIS SONG</button>   */}
           <PopupLicense song={curr_music?.id}/>
        </div>
   </div>
   </>
}
        </SongWrap>
    )
}


const SongWrap =  styled.div`

 audio {
    width: 100%;
    height: 50px;
}


/* audio::-webkit-media-controls-panel {
    background-color: gray;  
}

&::-webkit-media-controls-enclosure {
    border-radius: 5px;
    background-color: green;
}

audio::-webkit-media-controls-play-button{
    background-color:white;
    border-radius: 50px;
} */



`