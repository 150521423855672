import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { keyUri, config } from '../key'
import ErrorMessage from '../shared/errorMessage'
import SuccessMessage from '../shared/successMessage'


const token =  localStorage.getItem('access_token') ?
localStorage.getItem('access_token') : null


const initialState = {

    music_list:[],
    curr_music:null,
    loading:false,
    hasError:false,
    songlicenses:[],
    songtrack:[],
    samplesong:[],
    songstatus:[]

}

const loginConfig  = {
    headers: {
      Authorization: token,
    },
  }


export const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {

    getmusic: state => {
      state.loading = true;
    },

    getAll_music_success: (state, {payload})  =>{
        state.loading = false
        state.music_list = payload.list
    },

    get_curr_success: (state,{payload})  =>{
        state.loading = false
        state.curr_music = payload.data
    },

    get_songlisence_success: (state,{payload})  =>{

          state.loading = false
          state.songlicenses = payload.list
      },
      get_songtracks_success: (state,{payload})  =>{
     
          state.loading = false
          state.songtrack = payload.list
      },
      get_songsample_success: (state,{payload})  =>{

          state.loading = false
          state.samplesong = payload.list
      },
      get_songstatus_success: (state,{payload})  =>{
        console.log(payload); 
          state.loading = false
          state.songstatus = payload.list
      },


    get_music_Failure: (state) => {

      state.loading = false
      state.hasError = true
    },

  },
})


export const { getmusic ,getAll_music_success, get_music_Failure, get_curr_success,get_songlisence_success,get_songtracks_success ,
  get_songstatus_success,get_songsample_success} = musicSlice.actions;


export const musicSelector = state => state.music;



export const fetchMusicDetails = (id) => async dispatch => {
    dispatch(getmusic())
    
    try {

    const {data} = await axios.get(keyUri.BACKEND_URI +`/music?id=${id}`, loginConfig)
       
       dispatch(get_curr_success(data));
        
    } catch (error) {
    dispatch(get_music_Failure())
    error && ErrorMessage(error?.response?.data?.message)
  }
 };


 

export const getMymusic = (song) => async dispatch => {
  console.log(song);
  dispatch(getmusic())
  
  try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/my-music?type=${song}`,loginConfig)

     dispatch(get_songtracks_success(data));
      
  } catch (error) {
  dispatch(get_music_Failure())
  error && ErrorMessage(error?.response?.data?.message)
}
};


export const getMySamplemusic = (song) => async dispatch => {
  console.log(song);
  dispatch(getmusic())
  
  try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/my-music?type=${song}`,loginConfig)

     dispatch(get_songsample_success(data));
      
  } catch (error) {
  dispatch(get_music_Failure())
  error && ErrorMessage(error?.response?.data?.message)
}
};




export const getNFTMymusic = (song) => async dispatch => {
  dispatch(getmusic())
  
  try {

  const {data} = await axios.get(keyUri.BACKEND_URI +`/my-nft-musics?type=${song}`)
     
     dispatch(get_curr_success(data));
      
  } catch (error) {
  dispatch(get_music_Failure())
  error && ErrorMessage(error?.response?.data?.message)
}
};



export const sendOffer = (musicdata) => async dispatch =>{
  const key = 'offer';
  // dispatch(getmusic())
  try {
      const {data} = await axios.post(keyUri.BACKEND_URI + '/music/insterseted', musicdata, loginConfig)
      
      console.log(data);
//       // data && SuccessMessage(data.message)
      
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}


export const addMusic = (musicdata, song, history) => async dispatch =>{
  const key = 'music';
  dispatch(getmusic())
  try {
        const {data} = await axios.post(keyUri.BACKEND_URI + '/add-music', musicdata, loginConfig).then(({data})=>{
          console.log(data);
          const formData = new FormData()
          formData.append('file',song.file)
          formData.append('type',song.type)
          formData.append('id',data.id)
           axios.post(keyUri.BACKEND_URI + '/upload-music', formData, loginConfig)
           data && SuccessMessage( 'successfully added')
           data && history.push(`/user/home`)
      })

  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}

export const addRecord = (musicdata, song, history) => async dispatch =>{
  const key = 'music';
  dispatch(getmusic())
  try {
        const {data} = await axios.post(keyUri.BACKEND_URI + '/add-music', musicdata, loginConfig).then(({data})=>{
    
          const formData = new FormData()
          formData.append('file',song.file)
          formData.append('type',song.type)
          formData.append('id',data.id)
          
      axios.post(keyUri.BACKEND_URI + '/upload-music', formData, loginConfig)
           data && SuccessMessage( 'successfully added')
           data && history.push(`/user/home`)
      })

  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}


/******** new api ********/
export const MySongLicence = () => async dispatch =>{

  dispatch(getmusic())
  try {
  

      const {data} = await axios.get(keyUri.BACKEND_URI + `/my-license-request`, loginConfig)
  
     dispatch(get_songlisence_success(data))
      
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}


export const DeletemySongLicence = (id) => async dispatch =>{

  dispatch(getmusic())
  try {
  

      const {data} = await axios.delete(keyUri.BACKEND_URI + `/offer?id=${id}`, loginConfig)
      data && SuccessMessage(data.message)
      dispatch(MySongLicence())
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}


export const MySongLicencerequest = (status) => async dispatch =>{
  console.log(status);

  dispatch(getmusic())
  try {
  
      const {data} = await axios.get(keyUri.BACKEND_URI + `/license-request?type=${status}`, loginConfig)
      console.log(data);
      dispatch(get_songstatus_success(data))
//       // data && SuccessMessage(data.message)
      
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}




export const MySongLicencerIntrested = (value) => async dispatch =>{

  dispatch(getmusic())
  try {
  

      const {data} = await axios.get(keyUri.BACKEND_URI + `/insterseted?status=${value}`, loginConfig)
      
      console.log(data);
//       // data && SuccessMessage(data.message)
      
  } catch (error) {
    error && ErrorMessage(error?.response?.data?.message)
    dispatch(get_music_Failure())
    }
}


export default musicSlice.reducer;
