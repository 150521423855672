import React, {useEffect} from 'react'
import Backgroundimage from '../../Images/Background/BG_Landing.jpg'
import { Link } from 'react-router-dom'
import Fb from '../../Images/Social/FB.png'
import Google from '../../Images/Social/GOOGLE.png' 
import { useForm } from "react-hook-form";
import Error from '../../shared/error';
import {fetchlogin, authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import { message, Button } from 'antd';
import Logo1 from '../../Images/Asset 15.png'
import GooglePlay from '../../Images/Social/google-play1.png'
import App from '../../Images/Social/app.png'


export default function LandingPage({history}) {

  const dispatch = useDispatch()
  const { isAuthenticate } = useSelector(authenticateSelector)


  const { register, handleSubmit, formState: { errors }, setFocus  } = useForm({

    mode:"onTouched"
});




useEffect(()=>{
  
  if(isAuthenticate) {
    window.location.href = '/user/home'
  
  } else {
      history.push('/')
  }
}, [isAuthenticate])
  



const onSubmit = (value) => {
const data = {
  input:value.email,
  password:value.password,
}
console.log(data);

dispatch(fetchlogin(data))
};



    return ( <>
         <div className="min-h-full bg-fixed bg-cover bg-center  pt-5 hidden sm:block" style={{backgroundImage:`url('${Backgroundimage}')`}}> 
    
            <section class="text-gray-600 body-font">

    <div class="container px-5 py-36 pt-36 mx-auto flex flex-wrap items-center">
    <div class="lg:w-2/6 md:w-1/2  rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">


<form onSubmit={handleSubmit(onSubmit)}>

            <div class="relative mb-4">
                    <input  {...register("email", { required: true })}  type="email" id="email" autoComplete="off" placeholder="Email/Username" class="w-full bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-xs text-gray-500 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                    {errors.name?.type === "required" && <Error msg="required!" />}
            </div>

          <div class="relative mb-4">
            <input  {...register("password", { required: true })}  type="password" id="password"  autoComplete="off" placeholder="Password" class="w-full text-xs bg-white rounded border border-gray-300 focus:border-yellow-500 focus:ring-2 focus:ring-yellow-200  outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            {errors.name?.type === "required" && <Error msg="required!" />}
      
          <Link to="/forgotpassword">
              <p class="flex flex-row-reverse text-sm text-white mt-3 mb-6">Forgot password?</p>
          </Link>
      </div>

      <input  className=" w-full text-white bg-yellow-400 font-bold border-0 py-4 px-8 focus:outline-none border-black hover:bg-yellow-500 rounded-lg text-lg cursor-pointer" value="LOGIN"   type="submit" />


</form> 

    

<hr style={{borderTop: "3px solid white"}}></hr>

<Link to="/register">
        <p class="flex justify-center text-sm text-white mt-3 mb-5">Don't have an Account?
        <span className=" px-2 font-semibold"> Sign Up!</span>
        </p>
        </Link>
    </div>
  </div>
  
</section>
        </div>

        <div className="text-white min-h-screen flex flex-col items-center sm:hidden" style={{background:`linear-gradient(203deg, rgba(255,58,57,1) 0%, rgba(242,42,100,1) 18%,  rgba(228,25,142,1) 33%, rgba(229,26,139,1) 33%, rgba(199,25,184,1) 60%, 
        rgba(130,25,228,1) 89%, rgba(116,16,210,1) 100%)`, backgroundAttachment:'fixed'}} >
          
          <div  className=" bg-black bg-opacity-20 backdrop-filter backdrop-blur h-screen w-screen pt-20 " >

            <img src={Logo1} alt="Charlie" className="h-12  mx-auto" />

          <p className=" mt-24 text-xl text-center">Download the App from</p>

            <div className="grid grid-cols-2 gap-x-1 mt-9 mx-14">
                    <div ><img src={GooglePlay} alt="Google play" className="h-16 mx-auto mb-1" /> <p className="text-center">Google Play</p> </div>
                    <div><img src={App} alt="App Store" className="h-16 mx-auto mb-1" /><p className="text-center">App Store</p> </div>
            </div>

            </div>
       </div>
        </>
    )
}

