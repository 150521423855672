import React, {useState, useEffect} from 'react'
import Modal from 'react-modal';
import Switch from "react-switch";
import { AiOutlinePlus } from "react-icons/ai";
import styled from 'styled-components'
import { HiX} from 'react-icons/hi';
import {Link} from 'react-router-dom'
import {authenticateSelector} from '../../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import {fetchAllfilter,filterSelector} from '../../api/filterlist'
import { useHistory } from 'react-router-dom';
import {fetchSearch} from '../../api/home'


export default function Popupf1() {

  const dispatch = useDispatch()
  let history = useHistory();

  const {  user } = useSelector(authenticateSelector)
  const {filter_list} = useSelector(filterSelector)

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [list, setList] = React.useState([]);
    const [sublist, setSubList] = React.useState(null);


    const [filter, setFilter]= useState('GENRE')

    const [instrumentList, setInstrumentList]= useState([])
    const [moodList, setMoodList]= useState([])
    const [vocalList, setVocalList]= useState([])
    const [bpmsList, setBpmList]= useState([])
    const [pitchList, setPitchList]= useState([])
    const [languageList, setLanguageList]= useState([])
    const [genresList, setGenresList]= useState([])
    const [categoryList, setCategoryList]= useState([])
    const [themeList, setThemeList]= useState([])
    const [tempoList, setTempoList]= useState([])
    
    
        useEffect(() => {
          dispatch(fetchAllfilter())
        }, [dispatch])
       

      const onClickButton = (type)=>{
          setList(filter_list)
          setSubList(filter_list.find((item) => item.type === type ))
          setIsOpen(true);
      }

      const handleClickItem = (data) =>{
        setFilter(data.type)
        setSubList(data)
      }


      
const onSubmit = () => {

      const data = {
          offset: 0,
          instrument_ids:instrumentList,
          mood_ids:moodList,
          vocaltype_ids:vocalList,
          bpms_ids:bpmsList,
          pitch_ids:pitchList,
          language_ids:languageList,
          gener_ids:genresList,
          keyword:'',
          theme_ids:themeList,
          category_ids:categoryList,
          tempo_ids:tempoList
      }

          dispatch(fetchSearch(data))
          history.push(`/user/search`)
          setIsOpen(false);
    };



 const onClear = ()=>{
    setInstrumentList([])
    setMoodList([])
    setVocalList([])
    setBpmList([])
    setPitchList([])
    setLanguageList([])
    setGenresList([])
    setCategoryList([])
    setThemeList([])
    setTempoList([])
 }


      const onChangeSwitch = (id, filter)=>{

        switch (filter) {
            case 'GENRE':
                    const  istheregenres =  genresList.findIndex(item => item === id?.id)

                    if (istheregenres !== -1 ){
                        let arr = genresList.filter(item => item !== id?.id ) 
                        setGenresList(arr)

                    } else {
                        setGenresList([...genresList,id.id])
                    } 
                break;

            case 'LANGUAGE':
                const istherelanguage =  languageList.findIndex(item => item === id?.id)

                    if (istherelanguage !== -1 ){
                        let arr = languageList.filter(item => item !== id?.id ) 
                        setLanguageList(arr)

                    } else {
                        setLanguageList([...languageList,id.id])
                    } 
                break;

                case 'INSTRUMENT':
                  const isthereinstruments = instrumentList.findIndex(item => item === id?.id)

                  if (isthereinstruments !== -1 ){
                      let arr = instrumentList.filter(item => item !== id?.id ) 
                      setInstrumentList(arr)

                  } else {
                      setInstrumentList([...instrumentList,id.id])
                  } 
                break;

            case 'PITCH':
              const istherePitch =  pitchList.findIndex(item => item=== id?.id)

                  if (istherePitch !== -1 ){
                      let arr = pitchList.filter(item => item !== id?.id ) 
                      setPitchList(arr)

                  } else {
                      setPitchList([...pitchList,id.id])
                  } 
              break;

            case 'THEME':
              const istheretheme =  themeList.findIndex(item => item === id?.id)

                  if (istheretheme !== -1 ){
                      let arr = themeList.filter(item => item !== id?.id ) 
                      setThemeList(arr)

                  } else {
                      setThemeList([...themeList,id.id])
                  } 
              break;

            case 'MOOD':
              const istheremood =  moodList.findIndex(item => item === id?.id)

                  if (istheremood !== -1 ){
                      let arr = moodList.filter(item => item !== id?.id ) 
                      setMoodList(arr)

                  } else {
                    setMoodList([...moodList,id.id])
                  } 
              break;

            case 'VOCAL':
              const istherevocal =  vocalList.findIndex(item => item === id?.id)

                  if (istherevocal !== -1 ){
                      let arr = vocalList.filter(item => item !== id?.id ) 
                      setVocalList(arr)

                  } else {
                    setVocalList([...vocalList,id.id])
                  } 
              break;
          

            case 'BPMS':
              const istherebpms =  bpmsList.findIndex(item => item === id?.id)

                  if (istherebpms !== -1 ){
                      let arr = bpmsList.filter(item => item !== id?.id ) 
                      setBpmList(arr)

                  } else {
                    setBpmList([...bpmsList,id.id])
                  } 
              break;

              case 'TimeSignatures':
              const istheretime =  tempoList.findIndex(item => item === id?.id)

                  if (istheretime !== -1 ){
                      let arr = tempoList.filter(item => item !== id?.id ) 
                      setTempoList(arr)

                  } else {
                    setTempoList([...tempoList,id.id])
                  } 
              break;

              case 'SongType':
              const isthereSongtype =  categoryList.findIndex(item => item === id?.id)

                  if (isthereSongtype !== -1 ){
                      let arr = categoryList.filter(item => item !== id?.id ) 
                      setCategoryList(arr)

                  } else {
                    setCategoryList([...categoryList,id.id])
                  } 
              break;
          }
    }

      function closeModal() {
        setIsOpen(false);
      }

      const props ={
        onColor:"#F99E0B",
        onHandleColor:"#FDFEFE",
        handleDiameter:20,
        uncheckedIcon:false,
        checkedIcon:false,
        boxShadow:"0px 1px 5px rgba(0, 0, 0, 0.6)",
        activeBoxShadow:"0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
        height:15,
        width:40,
        className:"react-switch",
        id:"material-switch"
    }
    


    return (
        <div>

       <div className=" flex flex-row text-white">

          {/* {user?.aboutus === "ARTIST" && <Link to="/user/upload-music"> <button className="text-white bg-yellow-500  rounded-full px-4 py-2 ml-0 mr-6 m-4 w-44">
                  <div className="flex justify-between items-center p-0 m-0"><AiOutlinePlus className="text-xl" />Upload Music</div>
            </button></Link>} */}

            <button className=" bg-black  px-8 py-2 m-4 ml-0 rounded" onClick={()=>onClickButton('GENRE')}> All</button>
            <button className=" bg-black bg-opacity-40   px-8 py-2 m-4 rounded" onClick={()=>onClickButton('GENRE')}> Genres </button>
            <button className=" bg-black bg-opacity-40 px-8 py-2 m-4 rounded"  onClick={()=>onClickButton('LANGUAGE')}> Language </button>
            <button className=" bg-black bg-opacity-40 px-6 py-2 m-4 w-28 rounded"  onClick={()=>onClickButton('GENRE')}> 
                  <div className="flex justify-between items-center"><AiOutlinePlus className="text-l" />More</div> 
            </button>
 
      </div>

    <Modal
      style={{
        overlay: {
            position: 'fixed',
            top: 0 ,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            backdropFilter: 'blur(4px)',
          
          },
          content:{

            width:"80%",
            height:"80%",
            margin:"auto",
            backgroundColor:"#000000",
            opacity:0.7,
            color:"#f5f5f5",
            border:"none",
            borderRadius:"25px"
          }
      }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <button className="float-right block  text-3xl" onClick={closeModal}><HiX/></button>

        <h1 className="text-white  text-2xl mx-4" >Sort By</h1>

        <hr className="my-4 mx-4 " style={{width:'97%'}} />


         <br/>
                
        <div className="grid grid-cols-8 px-4   ">
          <div className="col-span-2">
        {

          list.map((item, i)=>{

            return  <React.Fragment key={i}>
                <div>
                  <h2 className={`cursor-pointer my-3 text-lg ${sublist?.title === item?.title ? `text-yellow-400` : `text-white` }`} onClick={()=>handleClickItem(item)}>{item.title}</h2>

                </div>
                </React.Fragment>

          })
        } 
        
        </div>
        <FormWrap className="col-span-6 ">
        <div className="grid grid-cols-3 gap-5 overflow-x-hidden  overflow-y-auto musiclist " style={{ maxHeight:"52vh"}} >

          {
            sublist?.list.map((sitem, k)=>{
              
              const isthere = sublist?.type === 'GENRE' ? genresList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'LANGUAGE' ? languageList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'INSTRUMENT' ? instrumentList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'PITCH' ? pitchList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'THEME' ? themeList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'MOOD' ? moodList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'VOCAL' ? vocalList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'BPMS' ? bpmsList.findIndex(item => item === sitem.id) : 
              sublist?.type === 'TimeSignatures' ? tempoList.findIndex(item => item === sitem.id) :
              sublist?.type === 'SongType' ? categoryList.findIndex(item => item === sitem.id) : []

            return  <div key={k} className=" col-span-1 flex justify-between items-center " >

              <div className="flex justify-between w-full mr-7">
                  <div >
                    {
                    sublist?.type === 'TimeSignatures' ? <label for="html">{sitem?.from_time}-{sitem?.to_time}</label> :
                    <label for="html">{sitem?.name}</label>
                    }
                  </div>
                  <div >
                    <Switch 
                        value={sitem?.id}
                        checked={isthere !== -1 ? true : false}
                        onChange={(_)=>onChangeSwitch(sitem, filter)}
                        {...props}
                    />
                  </div>
              </div>
              
        </div>
    })
  }

</div>
</FormWrap>

</div>

    <div className="flex justify-end pt-10   " style={{position:'absolute' , bottom:'45px' , right:'45px'}}>


        <button class="text-black border-black shadow-sm  bg-white font-bold border-0 py-2 px-8  focus:outline-none 
        hover:bg-gray-500 rounded-lg text-lg  mx-6  " onClick={onClear}>  Clear All   </button>


        <button class="text-white   border-black shadow-sm bg-yellow-400 font-bold border-0 py-2 px-12 focus:outline-none 
        hover:bg-yellow-500 rounded-lg text-lg  " onClick={onSubmit}>  Apply   </button>


  </div>
  
      </Modal>
        </div>
    )
}


const FormWrap= styled.div`


.musiclist{




}


`